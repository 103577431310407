.login-container {
  background: url("../loginLogo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .container .forms{
    display: flex;
    align-items: center;
    height: 440px;
    width: 200%;
    transition: height 0.2s ease;
} */

/* .container .form{
    width: 50%;
    padding: 30px;
    background-color: #fff;
    transition: margin-left 0.18s ease;
} */
.login-container .forms {
  margin-top: 17.5%;
}
.login-container.active .login {
  margin-left: -50%;
  opacity: 0;
  transition: margin-left 0.18s ease, opacity 0.15s ease;
}

.login-container .signup {
  opacity: 0;
  transition: opacity 0.09s ease;
}
.login-container.active .signup {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.login-container.active .forms {
  height: 600px;
}
.login-container .form .title {
  position: relative;
  font-size: 27px;
  font-weight: 600;
}
.form .input-container {
  margin-top: 50px;
}
.form .input-field {
  position: relative;
  height: 45px;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  display: flex;
  color: rgba(0, 0, 0, 0);
}

.input-field input {
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: 70%;
  padding: 0 35px;
  border: none;
  outline: none;
  font-size: 16px;
  border-radius: 60px;
  border-bottom: 1px solid #064b4f;
  border-right: 1px solid #064b4f;
  border-left: 1px solid #064b4f;
  border-top: 1px solid #064b4f;
  transition: all 0.2s ease;
}

.input-field input:is(:focus, :valid) {
  border-bottom-color: #064b4f;
  border-top-color: #064b4f;
}

.input-field i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  font-size: 23px;
  transition: all 0.2s ease;
}

.input-field input:is(:focus, :valid) ~ i {
  color: #4070f4;
}

.input-field i.icon {
  left: 0;
}
.input-field i.showHidePw {
  right: 0;
  cursor: pointer;
  padding: 10px;
}

.form .checkbox-text {
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  /* justify-content: center;
    display: flex; */
}

.checkbox-text .checkbox-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 36%;
  display: flex;
}

.checkbox-content input {
  margin: 0 8px -2px 4px;
  /* accent-color: #064b4f; */
}

.form .text {
  color: #333;
  font-size: 14px;
}

.form a.text {
  color: #064b4f;
  text-decoration: none;
}
.form a:hover {
  text-decoration: underline;
}

.form .button {
  margin-top: 35px;
  height: 45px;
  width: 100%;
}

.form .button input {
  /* width: 60% !important; */
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 60px;
  background-color: #064b4f;
  cursor: pointer;
  transition: all 0.9s ease;
}

.button-container {
  margin-top: -20px;
}
.button input:hover {
  background-color: #f3f1f1;
  border: 1px solid #064b4f;
  color: #064b4f;
}

.form .login-signup {
  margin-top: 20px;
  text-align: center;
  font-style: italic;
}
.login-showpassword{
  position: relative;
    top: 9px;
    left: 113px;
    color: #064b4f;
}
@media (max-width:920px) {
  .login-container {
  background: url("../loginLogo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 162vh;
  width: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
}
