.layout {
  display: grid;
  grid-template-columns: 10% 90%;
  overflow-x: hidden;
  background: #eff2f3;
}
.side-bar-logo {
  width: 50px !important;
  height: 50px !important;
  border-radius: 0px !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #ffffff !important;
  color: #064b4f !important;
}
div ul li a {
  background: 0 0;
  border: 0;
  border-radius: 0 !important;
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.default-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #606060;

  font-weight: 600;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
}
.account-default-avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #606060;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
}
.participant-default-avatar {
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #606060;

  font-weight: 600;
  font-size: 20px;
  line-height: 23px;

  color: #ffffff;
  width: 58px;
  height: 58px;
}
.team-controller {
  position: relative;
  right: -70px;
  width: 18px;
  height: 18px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.supplier-controller {
  width: 15px;
  height: 15px;

  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.assi .select-btn {
  background: #353945;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #ffffff;
  width: 80px;
}
.input-groups {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
}
.input-label {
  justify-self: end;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}

.page {
  min-height: 100vh;
  overflow-y: hidden;
  background: #eff2f3;
  padding-top: 0px;
  padding-left: 50px !important;
  padding-right: 50px  !important;
  padding-bottom: 2rem  !important;
  overflow-x: hidden;
}
.primary {
  background-color: #064b4f;
  color: #ffffff;
}
.accent {
  background-color: #186569;
  color: #ffffff;
}
.neutral1 {
  background-color: #aeb8c9;
  color: #ffffff;
}
.neutra2 {
  background-color: #e2e7f0;
  color: #ffffff;
}
.waring {
  background-color: #ffd752;
  color: #ffffff;
}
.danger {
  background-color: #ff5252;
  color: #ffffff;
}
.success {
  background-color: #52ffac;
  color: #ffffff;
}
.h1 {
  font-weight: bold;
  font-size: 54px;
  color: #353945;
  letter-spacing: 0.105em;
}
.h2 {
  font-weight: bold;
  font-size: 36px;
  color: #353945;
  letter-spacing: 0.105em;
}
.h3 {
  font-weight: bold;
  font-size: 30px;
  color: #353945;
  letter-spacing: 0.105em;
}
.h4 {
  font-weight: bold;
  font-size: 24px;
  color: #353945;
  letter-spacing: 0.105em;
}
.h4-pri {
  font-weight: bold;
  font-size: 24px;
  color: #186569;
  letter-spacing: 0.105em;
}
.h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  /* Text */

  color: #353945;
}
.h5-pri {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569;
}
.plg {
  font-size: 14px;
  color: #353945;
  letter-spacing: 0.105em;
  font-weight: 400;
}
.prg-error {
  font-size: 12px;
  font-weight: 400;
}
.prg {
  font-size: 14px;
  color: #353945 !important;
  letter-spacing: 0.105em;
  font-weight: 400;
}
.group-prg {
  font-size: 14px;
  color: #353945 !important;
  letter-spacing: 0.105em;
  font-weight: 400;
  width: 442px;
}
.prg-white {
  font-size: 14px;
  color: #ffff !important;
  letter-spacing: 0.105em;
  font-weight: 400;
}
.prg a {
  font-size: 14px;
  color: #353945 !important;
  letter-spacing: 0.105em;
  font-weight: 400;
}
.prg-pri {
  font-size: 14px;
  color: #186569 !important;
  letter-spacing: 0.105em;
  font-weight: 400;
  font-weight: bold;
}
.btn-rounded-fil {
  background-color: #064b4f;
  border-radius: 25px;
  color: #ffffff;
  padding: 8px 28px 8px 28px;

  line-height: 14px;
}
.btn-rounded-outline {
  background-color: #ffffff;
  border-radius: 25px;
  border: 1px solid #064b4f;
  color: #064b4f;
  padding: 8px 28px 8px 28px;

  line-height: 14px;
}
.btn-cancel {
  background: #aeb8c9;
  border-radius: 25px;
  color: #353945;
  padding: 8px 28px 8px 28px;

  line-height: 14px;
}
.card-md {
  height: 188px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 15%);
  border-radius: 6px;
}
.tag-rounded {
  width: 141px;
  height: 36px;
  background: #eff2f3;
  border-radius: 25px;
  padding: 11px 32px 11px 32px;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #353945;
}
.tags {
  width: 142px;
  height: 47px;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  padding: 14px 55px 14px 55px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.sidebar-text {
  margin-top: 200px;
}
.all-breadcrumb {
  margin-left: 0px;
  width: 100%;
  border-bottom: 1px solid #aeb8c9;
  margin-bottom: 29px;
}
.dropdownorder {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 160px;
}

.dropdownorder ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownorder li {
  padding: 5px;
  cursor: pointer;
  color:#064b4f;
  font-size: 20px;
}

.dropdownorder li:hover {
  background-color: #f0f0f0;
}

.card-title {
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  padding-left: 20px;
  display: flex;
  align-items: center;
}
.medium-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  flex-direction: row-reverse;

  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;

  bottom: 0%;
  width: 100%; /* Full width */
  /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.medium-modal-content {
  margin-right: -13px;
  background-color: #fefefe;
  height: 100%;
  border: 1px solid #888;
  width: 50%;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height */
  height: fit-content;
}
.large-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  flex-direction: row-reverse;

  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;

  bottom: 0%;
  width: 100%; /* Full width */
  /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.large-modal-content {
  background-color: #fefefe;
  height: 100%;
  border: 1px solid #888;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height */
  height: fit-content;
}
.dialog-modal-content {
  background: #ffffff;
  border-radius: 6px;
  width: 447px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}

.dialog-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  height: 100vh;
  top: 0;
  width: 100%; /* Full width */
  /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
}
.edit-mini-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  height: 100vh;
  top: 0;
  width: 100%; /* Full width */
  /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;
}
.edit-modal-divider {
  height: 0px;
  width: 80%;
  border-bottom: 1px solid #e2e7f0;
}
.edit-dialog-info {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.edit-mini-modal-content {
  background: #ffffff;
  border-radius: 6px;
  width: 447px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}
.edit-mini-large-modal-content {
  background: #ffffff;
  border-radius: 6px;
  width: 447px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}
.dialog-info {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.icon-color {
  color: #064b4f;
}
.nav-link-submenu {
  color: #064b4f;
}
.confirm-dialog-controller {
  display: flex;
  gap: 24px;
}
.disable-scroll {
  overflow-y: hidden !important;
}
.dialog-cancel-btn {
  height: 32px;
  width: 112px;
  cursor: pointer;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #aeb8c9;
  border-radius: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.edit-input-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.multi-phone-number {
  width: 250px;
  height: 30px;
  outline: none;
  border: none;
  background: #eff2f3;
  border-radius: 4px;
}
.dialog-delete-btn {
  cursor: pointer;
  height: 32px;
  width: 112px;
  left: 0px;
  top: 0px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff5252;
  border-radius: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  color: #ffffff;
  outline: none;
}
.rma-tab {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  /* padding-top: 0.5rem;
  text-align: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 6px; */
}
.form-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 10px;
  align-items: center;
}
.inputs-group {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 75%;
}
.download-btn {
  border: 1.5px solid #064b4f;
  border-radius: 25px;
  outline: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Primary */

  color: #064b4f;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 20px;
  padding-right: 20px;
  height: 32px;
}
.supplier-text-input {
  width: 199px;
  height: 32px;
  left: 1042px;
  top: 130px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 14px;
}

.supplier-input-label {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  min-width: 160px;
  letter-spacing: 0.105em;
  color: #353945;
  display: flex;
  justify-content: flex-end;
}
.supplier-text-input-textarea {
  width: 199px;
  left: 1042px;
  top: 130px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 14px;
}
.sourcing-text-input-textarea {
  width: 250px;
  left: 1042px;
  top: 130px;
  height: 118px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
  margin: 0 0px;
}
.import-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #353945;
  background: #ffffff;
  border-radius: 1rem;
  width: 159px;
  height: 23px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border: 1px solid #353945;
}
.sidebar-text {
  margin-top: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Primary */

  color: #064b4f;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.main-sidebar-text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #f5f5f5;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.spms-option {
  background: #ffffff !important;
}
.project-card-divider {
  border-bottom: 1px solid #ececec;
}
.text-input {
  width: 200px;
  height: 20px;
  left: 803px;
  top: 120px;

  /* Body */

  background: #eff2f3;
  outline: none;
  border: none;
  height: 32px;

  background: #eff2f3;
  border-radius: 6px;
}
.supplier-text-input-edit {
  width: 140px;
  height: 35px;
  left: 1042px;
  top: 130px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
}
.supplier-select-option-edit {
  width: 144px;
  background: #ececec;
  border: 1px solid #ececec;
  border-radius: 6px;
  outline: none;
  height: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #353945;
}
.not-found {
  position: relative;
  width: 642px !important ;
  height: 642px !important;
  left: 229px;
}
.css-1s2u09g-control {
  background-color: #eff2f3 !important;
}
.select-option-assign-personnel {
  width: 261px;
  height: 32px;
  border: none;
  outline: none;
  /* Body */

  background: #eff2f3;
  border-radius: 4px;
}
.create-batch-form {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  align-items: center;
  padding-top: 30px;
}
.create-batch-option-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.create-bach-input-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;

  align-self: center;
}

.input-group-grid {
  display: grid;
  grid-template-columns: 105px 282px;
  gap: 12px;
}
.create-batch-checkbox-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding-top: 30px;
  justify-content: center;
}
.create-batch-items-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  width: 100%;
}

.items-manufacturer {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.items-description-divider {
  border-top: 1px solid #e2e7f0;
}
.items-checkbox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.batch-detail-container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.batch-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.pending-batch {
  display: flex;
  gap: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Accent */
  align-items: center;
  color: #186569;
}
.sync-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  outline: none;
  border-radius: 50%;
  border: none;
  /* Accent */
  color: #ffffff;
  background: #186569;
  mix-blend-mode: normal;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15); */
}
.sync-btn svg {
  margin-top: -2px;
}
.batch-detail-header-page {
  gap: 25px;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 18% 30% 17% 29%;
}
.batch-detail-page {
  display: grid;
  grid-template-columns: 69% 30%;
  gap: 22px;
}
@media (max-width: 635px) {
  .batch-detail-page {
    grid-template-columns: 100%;
  }
}
/* Add this CSS to your styles or create a separate stylesheet */
.dropdown-order {
  position: absolute;
    top: 30%;
    left: 13%;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 147px;
}

.dropdown-order ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-order li {
  padding: 8px 0;
}

.dropdown-order li:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.select-option-create-order {
  padding: 8px;
  margin-right: 8px;
}

/* Add more styles as needed */

.batch-detail-header-card {
  height: 96px;
  background: #ffffff;
  border: 0.5px solid #aeb8c9;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.centered-content-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  align-self: center;
  height: 100%;
}
.centered-content-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  align-self: center;
  height: 100%;
}
.start-content-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  align-self: center;
  height: 100%;
}
.normal-sized-img {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  object-fit: cover;
}
.batch-lg-officer-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.date-value-origin {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.batch-detail-status-card {
  padding: 20px;
}
.status-description {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  width: 220px;
  text-align: justify;
}
.start-content-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  align-self: center;
  height: 100%;
}
.batch-detail-right-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.batch-detail-right-section-status {
  height: fit-content;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  padding-top: 16px;
  gap: 5px;
}
.status-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
  text-align: center;
}
.batch-detail-active-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;

  margin-left: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  padding-left: 3px;
  padding-right: 3px;
  height: 2rem !important;
  cursor: pointer;
  border-radius: 5px;
}
.batch-detail-payment-section {
  padding-top: 16px;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  gap: 1rem;
  height: 400px;
  display: flex;
  flex-direction: column;
}
.batch-detail-payment-detail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;

  gap: 5px;
}
.card-sm-value-payment {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.paired-label {
  text-align: end;
}
.close-modal-button {
  cursor: pointer;
}
.centered-content-row svg {
  cursor: pointer;
}
.supplier-filter {
  display: flex;
  width: 261px;
  height: 32px;
  background: #ececec;
  border: 1px solid #ececec;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  padding-left: 1px;
  padding-right: 1px;
  color: #000;
}
.supplier-search {
  background: #ececec;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  width: 330px;
  /* identical to box height */

  /* Text */

  color: #353945;
}
.supplier-search::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text */

  color: #353945;
}
.breadcrumb {
  margin-left: 1rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  list-style: none;
  margin-bottom: 0px !important;
}
.sync-btn :hover {
  /* color: #186569;
  border: 2px solid #064b4f;
  background: #ffffff;
  width: 100%;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  outline: none;
  border-radius: 50%;
  border: none; */
  /* Accent */
}
.more-avatars {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  padding-top: 6px;
  padding-left: 4px;
}
.create-group-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #064b4f;
  background: #ffffff;
  border-radius: 1rem;
  width: 159px;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border: 2px solid #064b4f;
}
.create-group-btn:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #ffffff;
  background: #064b4f;
  border-radius: 1rem;
  width: 159px;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.css-kvsszq-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: #e2e7f0 !important;
  height: 23px !important;
  font-size: 14px !important;
  color: #353945 !important;
  font-weight: 400 !important;
  font-weight: normal !important;
}
.css-kvsszq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #186569 !important;
  color: #fff !important;
}
.small-fileChooser {
  width: 100px !important;
  background: #eff2f3;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: right center;
  height: 32px;
}
.small-text-field {
  width: 90px;
  height: 32px;

  /* Body */

  background: #eff2f3;
  border-radius: 6px;

  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
  border: none;
  outline: none;
}
.small-input-group {
  display: flex;
  gap: 6px;
}
.bank-input-label {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  width: fit-content;
  letter-spacing: 0.105em;
  color: #353945;
  display: flex;
  justify-content: flex-end;
}
.batch-detail-active-link:hover {
  color: #186569;
  background-color: rgba(25, 118, 210, 0.04);
  transition: all 0.1s ease-in-out;
}
.batch-detail-active-link:hover .text-btn-label {
  border-bottom: 1px solid #186569;
  color: #186569 !important;
}
.forex-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 15px;
  color: #186569;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  width: -moz-fit-content;
  width: fit-content;
  color: #186569;
  display: flex;
  min-width: 3rem;
  margin-bottom: 18px;
  margin-top: 13px;
}
.forex-checkbox-group div {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-direction: row;
  justify-content: flex-start;
}
.row-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.no-record {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.is-supplier {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
.no-record img {
  width: 5rem;
  height: 5rem;
  border-radius: 0px;
}
.is-supplier img {
  width: 10rem;
  height: 5rem;
  border-radius: 0px;
}
.no-record-label {
  /* font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945; */
}
.local-border-none {
  border: none;
}
.logisticsOfficer-ctn {
  display: flex;
  /* width: 300px; */
  flex-direction: column;
  height: 96px;
  overflow-y: scroll;
}
.logistics-officers {
  height: 96px;
}
.view-more-order {
  width: 105px;
  height: 37px;
  text-transform: none;
  font-size: 13px;
  font-weight: normal;
  background-color: #fff;
  border-radius: 4px;
  color: #186569;
  border: 1px solid #186569;
}
.view-more-order:hover {
  background-color: #186569;
  color: #fff;
  border: 1px solid #fff;
}
.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #064b4f;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}
.client-info-header {
  justify-content: space-between;
}
.form-container-edit-project {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 10px;
  align-items: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.radio-group {
  display: flex;
  gap: 3px;
  /* align-items: center; */
  justify-content: center;
}
.activeRadio {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.inActiveRadio {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.shipment-arrangement-form-radio-group {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}
.sub-side-menu {
  height: 100vh;
  width: 157px;
  background-color: rgb(24, 101, 105);
  top: 0px;
  left: 8%;
  bottom: 0px;
  position: fixed;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #19797e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #19797e;
  border-color: #19797e;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  gap: 3px;
}
.page-link {
  position: relative;
  display: block;
  color: #19797e;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.Tab-stock {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 20px;
  gap: 10px;
  width: 100%;
  grid-template-columns: 12% 12% 12% 12% 12% 12% 12%;
  align-items: center;
  height: 40px;
  padding: 20px;
}
@media (max-width: 920px) {
  .Tab-stock {
    margin-bottom: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    gap: 10px;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 10px;
    height: 40px;
  }
  .side-nav {
    display: none;
  }
  .layout {
    grid-template-columns: 100%;
  }
  .not-found {
    position: relative;
    width: 100% !important;
    height: 80% !important;
    left: 0;
  }
}
