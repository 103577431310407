.initiateForex-button {
  text-transform: none;
  height: 24px;
  padding-top: 3px;
  color: #fff;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
}
.forex-detail-card {
  box-sizing: border-box;
  height: 154px;

  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
}
.forex-detail-card-sub {
  width: 100%;
  display: flex;
  justify-content: center;
}
.forex-card-left-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.forex-bank-and-item-container {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  padding-top: 30px;
  /* gap: 30px; */
}
.guarantee-balance-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  gap: 100px;
}
.guarantee-card {
  background-color: white;
  height: 180px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.08);
  flex-grow: 3;
  flex-basis: 25%;
}
.gross-card {
  background-color: white;
  min-height: 200px;
  display: grid;
  align-items: center;
  border-radius: 6px;
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.08);
  flex-grow: 3;
  flex-basis: 25%;
}
.origin-values {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569;
}
.cost-card {
  background: white;
  min-height: 320px;
}
.cost-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  background: #185656;
  color: white;
}
.line {
  width: 0.5px;
  background-color: #000;
  min-height: 100%;
  display: flex;
  justify-content: center;
}
.cost-value {
  font-weight: 500;
  font-size: 10px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945;
}
.cost-data-container {
  display: grid;
  grid-template-columns: 47% 2% 48%;
  padding: 20px;
  gap: 20px;
}
.forex-projectModal {
  background-color: #fefefe;
  margin-left: 61.6%;
  height: 100%;
  border: 1px solid #888;
  width: 518px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.forex-initiate-forex-modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  margin-top: 5px;
  margin-bottom: 35px;
  color: #353945;
  height: 45px;
  border-bottom: 1px solid #e2e7f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: unset !important;
}
.initiateForex-title {
  margin-right: 38%;
}

.forex-select-option {
  width: 226px;
  background: #ececec;
  border: 1px solid #ececec;
  color: #353945;
  border-radius: 3px;
  outline: none;
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.forex-select-option::placeholder {
  color: #aeb8c9;
}

.forex-select-option:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aeb8c9;
}

.forex-select-option::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aeb8c9;
}
.forex-input-fields {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  align-items: center;
  color: "#353945";
  gap: 5px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.105em;
  margin-left: 0px;
}

.rma-select-option {
  width: 326px;
  background: #fff;
  border: 1px solid #fff;
  border-bottom: 1px solid #555151;
  color: #353945;
  border-radius: 3px;
  outline: none;
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
}
.status-updater {
  width: 305px;
}

.rma-select-option::placeholder {
  color: #aeb8c9;
}

.rma-select-option:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aeb8c9;
}

.rma-select-option::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aeb8c9;
}
.forex-select {
  margin-top: 8px;
}

.forex-detail-container {
  display: grid;
  grid-template-columns: repeat(3, 32.5%);
  gap: 20px;
}

.forexApprovalNav {
  width: 100%;
}
/* .forex-receivable-nav {
  width: 115%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 200px 200px;
  column-gap: 3px;
  border-radius: 5px;
  border: 1px solid #ececec;
  justify-items: center;
  align-items: center;
} */
.forexPlaningNav {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-left: 15%;
  width: 700px;
}
.forex-receivable-mobile {
  width: 360px;
  margin-left: 1px;
}

.forexDetailDetailHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #353945;
  line-height: 19px;
  font-weight: 500;
  margin: 15px 15px 10px 20px;
  height: 50px;
  align-items: center;
  padding: 20px;
}
.forexPendingStatus {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569;
}
.forexPendingText {
  margin-right: 10px;
}
.forexOrderName {
  font-size: 20px;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  /* color: #186569; */
}
.forexOrderUnderline {
  margin-left: -20px;
  color: #aeb8c9;
  height: 1.5px;
}

.forexOrderInfo {
  border: "1px solid #AEB8C9";
  overflow-y: scroll;
  overflow-x: hidden;
  height: 520px;
}
.forexOrderInfoClient {
  border: "1px solid #AEB8C9";
  overflow-y: scroll;
  overflow-x: hidden;
  height: 670px;
}
.forexOrderList {
  border: "1px solid #AEB8C9";
  overflow-y: hidden;
  overflow-x: hidden;
  height: 580px;
}
.forex-remark {
  background-color: #fff;
  width: 100%;
  height: 228px;
  margin-top: 19px;
  border-radius: 6px;
  border: #aeb8c9 solid 1px;
  height: fit-content;
}
.forex-status-history {
  overflow-y: auto;
  height: 145px;
}

.remark-container {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 25px;
}
.forexOrderListClient {
  border: "1px solid #AEB8C9";
  overflow-y: scroll;
  overflow-x: hidden;
  height: 674px;
}

.forexOrderInfo::-webkit-scrollbar,
.forexOrderList::-webkit-scrollbar,
.forexOrderListClient::-webkit-scrollbar,
.forexOrderInfoClient::-webkit-scrollbar {
  width: 2px;
}
.forexOrderInfo::-webkit-scrollbar-track,
.forexOrderInfoClient::-webkit-scrollbar-track {
  background: #f7f5f5;
  border-radius: 3px;
  margin-top: -16px;
}
.forexOrderList::-webkit-scrollbar-track,
.forexOrderListClient::-webkit-scrollbar-track,
.forexOrderInfoClient::-webkit-scrollbar-track {
  background: #f7f5f5;
  border-radius: 3px;
  margin-top: 20px;
}
.forexOrderInfo::-webkit-scrollbar-thumb,
.forexOrderList::-webkit-scrollbar-thumb,
.forexOrderListClient::-webkit-scrollbar-thumb,
.forexOrderInfoClient::-webkit-scrollbar-thumb {
  background: #186569;
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

.forexOrderItemDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
  margin-left: 5%;
  margin-right: 5%;
}
.forexOrderItemDescriptionList {
  color: #353945;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.105em;
}

.summarizedPiButton {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 25px;
  margin-top: 10px;
  justify-content: space-between;
}
.forexTermAndConditions {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945;
  height: fit-content;
}

.devider {
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 10px;
  border-bottom: 1px solid #ececec;
}

.forextermAndConditions {
  display: flex;
  margin-left: -15px;
}
.paymentTermAndConditionsLabel {
  list-style-type: none;
  margin-top: 20px;
  cursor: context-menu;
  text-align: right;
}
.paymentTermAndConditionsValue {
  list-style-type: none;
  margin-top: 20px;
  cursor: context-menu;
  text-align: left;

}
.paymentTermAndConditionsLabel > li {
  margin-left: 10px;
}
.paymentTermAndConditionsValue > li {
  margin-left: -30px;
}

.paymentTcName {
  text-align: right;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #353945;
  margin-right: 10px;
  margin-top: 10px;
}

.paymentTcValue {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #186569;
  margin-top: 10px;
}

.paymentTcEditButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: -70px;
}
.requestLetterTitle {
  margin-right: 50%;
}
.bankSubmissionTitle {
  margin-right: 45%;
}
.swiftAndPermitsTitle {
  margin-right: 37%;
}
.forexApprovalForms {
  margin-top: -15px;
}
.initiateForexTitle {
  margin-right: 53%;
}
.AddNew {
  margin-right: 63%;
}
.editPayment {
  margin-right: 45%;
}
.updatePayment {
  margin-right: 45%;
}

.paymentForexApprovalinitiateForex {
  margin-top: -55px;
}
.paymentTrackingForex {
  margin-top: -55px;
  margin-left: auto;
}
.initiatePaymentCheckBox {
  display: flex;
  flex-direction: row;
  /* width: 0px; */
  justify-content: center;
  margin: auto;
}
.orderListCheckBox {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  margin-left: 15%;
}
.orderListDivider {
  margin-top: 10px;
}
.paymentTrackingDivider {
  margin-top: 15px;
  width: 150%;
  border-bottom: 1px solid #e2e7f0;
  margin-left: -70px;
}
.initiateForexModal {
  width: 518.5px;
}

.paymentDataTable {
  width: 100%;
  margin: auto;
}
.paymentTableHeader {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #d8deee;
}

.balanceContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #fff;
  border: 1px solid #d1cfcf;
}
.balanceTable {
  width: 100%;
  flex-direction: column;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: #353945;
}
table > thead {
  text-align: center;
}
tbody > tr {
  text-align: center !important;
}
tbody > tr > td #bankName {
  text-align: left !important;
}

.balancetableHeader {
  width: 100%;
  background-color: #ececec;
  height: 40px;
  border-bottom: 1px solid #d1cfcf !important;
}

.balancetableBodyRow {
  border-bottom: 1px solid #ececec !important;
}
.balancetableBodyRow:hover {
  background-color: #f7f7f7;
}
#bankName {
  text-align: left;
  display: block;
  padding-left: 42%;
}
#rollNo {
  text-align: left;
  padding-left: 8.2%;
}
#amount {
  text-align: left;
  padding-left: 13%;
}
.paymentTrackingContainer {
  width: 100%;
  height: 100%;

  /* display: none; */
}
.paymentTrackingAccordionCard {
  background-color: #eff2f3;
  border: none;
}
.paymentTrackingTitle {
  background-color: #eff2f3;
  border: none;
  width: 300px;
}
.paymentTrackingProjectName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  color: #353945;
  cursor: default;
}
.paymentTrackingUnderline {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c6d1e4;
}

.paymentTrackingSelectProject {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 40px;
}
.paymentTrackingSelectGapAnalysis {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-right: 40px;
}
.projectTotal {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.receivable-total-mobile-view {
  color: #aeb8c9;
}

.gapAnalysis {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #186569;
}
.paymentTrackingProjectTotal {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #353945;
}
.paymentTrackingTotalAmount {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #353945;
  margin-right: -20px;
  margin-left: 17px;
}

.forexProjectBankName {
  max-width: 80%;
}
.forexBold {
  font-weight: 600 !important;
}
.paymentPlanningInputLabel {
  margin-top: 30px;
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: center;
  font-style: normal;
  line-height: 13px;
  color: #aeb8c9;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
}
.paymentPlanningInputLabelForSelector {
  margin-top: 10px;
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: start;
  margin-left: -50px;
  font-style: normal;
  line-height: 13px;
  color: #353945;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
}
.paymentPlanningInputFields {
  margin-top: 30px;
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: center;
  font-style: normal;
  line-height: 13px;
  color: #353945;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
}

.payment-input-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: max-content;
  gap: 25px;
}
.paymentDetailInputLabel {
  margin-top: 8px;
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #353945;
  line-height: 13px;
  letter-spacing: 0.105em;
  color: #353945;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}
.paymentPaidInputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: max-content;
  margin-left: -60px;
  gap: 25px;
}

.paymentTracking {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 100px;
}
.payment-tracking-radio-button-lable {
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
}
.paymentTrackingForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: -55px;
}

.paymentDetailOrderSupplier {
  margin-top: 20px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.105em;
  color: #186569;
}
.paymentDetailOrderSupplierName {
  margin-top: -10px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.105em;
  color: #186569;
}
.paymentTrackingCardLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.paymentTrackingFormCardContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.rmaMissedItemStatusContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
}
.paymentTrackingFormCardInformation {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}
.paymentTrackingCardForm {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}
.paymentTrackingViewDetailDateInputLabels {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  height: max-content;
  gap: 25px;
}
.paymentTrackingViewDetailBalanceInputLabels {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: max-content;
  gap: 20px;
}
.payment-tracking-detail-origin-group {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.deviderForPaymentHistory {
  margin-left: -48%;
  margin-right: -18px;
  border-bottom: 1px solid #ececec;
}

input[type="checkbox"] {
  transform: scale(1.3);
}
input {
  accent-color: #186569;
}
.success_messages_text_style {
  line-height: 16px;
  letter-spacing: 0.105em;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #353945;
  letter-spacing: 0.105em;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}
.success_messages_text_style_mobile_View {
  line-height: 14px;
  letter-spacing: 0.105em;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #353945;
  letter-spacing: 0.105em;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

.forexApprovalStageLabels {
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #fff;
  text-transform: none !important;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
}

.forexApprovalStageLabels:hover {
  border-bottom: 1px solid #186569;
}

.forexApprovalStageValue {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  text-align: center;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  margin-bottom: 5px;
}
.summarized-pi-mobile {
  display: none;
}
.swift-and-permit-inputs {
  width: 171%;
  margin-left: -35%;
  margin-top: 5px;
  background: #eff2f3;
  border: 1px solid #eff2f3;
}
.forex-bank-mobile {
  display: grid;
  grid-template-columns: repeat(3, 310px);
  gap: 20px;
}
.balance-page-mobile-view {
  display: none;
}
.gap-analysis-mobile-view {
  display: none;
}
.gap-analysis-web-view {
  display: block;
}
.select-order-list-info-message {
  display: flex;
  justify-content: flex-start;
  padding-left: 80px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  margin-top: 30px;
  color: #ff5252;
}
.forex-attached-file-warning {
  display: flex;
  justify-content: center;
  /* padding-left: 50px; */
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.105em;
  margin-top: -50px;
  margin-bottom: 80px;
  color: #ff5252;
}
.swift-info-message {
  margin-top: -30px;
}
.gap-analysis-mobile-view-detail-card {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945;
}
.gap-analysis-mobile-view-container {
  display: none;
}
.gap-analysis-mobile-view-description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: -20px 20px 0px 20px;
}
.receivable-description {
  margin-top: 0px;
}

.gap-analysis-mobile-view-description-title {
  text-align: center;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #aeb8c9;
  margin-right: 10px;
  margin-top: 10px;
}
.mobile-view-label {
  text-align: right;
  font-family:
    Roboto,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #aeb8c9;
  margin-right: 10px;
  margin-top: 10px;
}
.payment-term-and-condition-input {
  width: 226px;
  background: #ececec;
  border: 1px solid #ececec;
  color: #353945;
  border-radius: 3px;
  height: 32px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  outline: none;
  margin-left: 4px;
  margin-top: 8px;
}
.summarized-pi-lc-warning {
  height: 52px;
  margin-top: -55px;
  margin-bottom: 60px;
  background: #186569;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #ffffff;

  display: flex;
  justify-content: center;
  gap: 4px;
}
@media (max-width: 768px) {
  .paymentDetailWrap {
    display: none;
  }
  .forex-detail-card {
    width: 365px;
  }
  .forexOrderInfo {
    height: 400px;
  }
  .summarized-pi-desktop {
    display: none;
  }
  .summarized-pi-mobile {
    display: flex;
  }
  .swift-and-permit-inputs {
    width: 143%;
    margin-left: -31px;
    margin-top: 5px;
  }
  .balanceContainer {
    display: none;
  }
  .forex-bank-mobile {
    display: flex;
    flex-direction: column;
  }
  .forex-balance-mobile {
    margin-bottom: 10px;
  }
  .balance-page-mobile-view {
    display: block;
  }
  .bank-amount {
    margin-top: 10px;
  }

  .forex-balance-mobile-value {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.105em;
    color: #aeb8c9;
  }
  .forex-balance-mobile-amount-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.105em;
    color: #186569;
  }
  .gap-analysis-mobile-view {
    /* margin-left: 25px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 4%;
  }

  .gap-analysis-web-view {
    display: none;
  }
  .gap-analysis-mobile-view-container {
    display: block;
  }
  .gap-analysis-mobile-label {
    font-family:
      Roboto,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #186569;
  }
  .gap-analysis-mobile-amount {
    font-family:
      Roboto,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 16px;
    color: #186569;
  }
  .forexOrderList {
    height: 760px;
  }
}
