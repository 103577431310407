.sm-card-container {
  display: grid;
  grid-template-columns:  400px 400px;
  gap: 20px;
  justify-content: center;
}
.sm-card {
  box-sizing: border-box;

  height: 125px;

  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.manufacturer {
  padding-left: 29px;
  padding-right: 29px;
  justify-content: space-between;
}
.total-orders {
  flex-direction: column !important;
  gap: 10px;
}
.Lc-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lc-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Primary */

  color: #064b4f;
}
.lc-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.sm-card-content-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.summary-table {
  margin-top: 25px;
  height: 616px;

  background: #ffffff;

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
}
.summary-table::-webkit-scrollbar {
  width: 5px;
}
.summary-table::-webkit-scrollbar-track {
  background: #f7f5f5;
  border-radius: 3px;
 
}
.summary-table::-webkit-scrollbar-thumb {
  background: #186569;
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}
.summary-header {
  text-align: center !important;
  border: none !important;
  box-shadow: none !important;
}
.summary-row {
  display: grid;
  grid-template-columns: 4% 3% 20% 10% 10% 10% 10% 18% 15%;
  text-align: center;
  height: 75px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
  margin-top: 20px;
}

.summary-last-row {
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr) 4fr;
  text-align: center;
  height: 75px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  letter-spacing: 0.105em;
}
.summary-row .no,
.summary-last-row .no {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.Profit {
  justify-content: center;
  height: 75px;
  display: flex;
  align-items: center;
}
.zero {
  background: #ffd752 !important;
}
.positive {
  background: #52ffac;
}
.negative {
  background: #ff5252;
}
@media (max-width: 920px) {
  .summary-row {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
    height: fit-content;
    align-items: center;
    border: 1px solid #e2e7f0;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.105em;
    color: #353945;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .label-container {
    width: 100%;
    display: grid;
    /* gap:50px; */
    grid-template-columns: 140px 160px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .pa-label {
    display: block;
    width: 150px;
    color: #aeb8c9;
    display: flex;
  }
  .sm-card-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
    justify-content: center;
  }
  .summary-header {
    display: none;
  }
}
