.tab-btn {
  width: 3rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  outline: none;
  border: none;
  color: #186569 !important;
  margin-top: 6px;
  background: #ffffff;
}
.sourcing-active-tab {
  border: 1.5px solid #186569 !important;
  border-radius: 25px !important;
}
.pa-stage-tab {
  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 25px;
  width: 70%;
  justify-self: center;
  height: 40px;
  margin: auto;
  justify-content: center;
  display: flex;
  gap: 1rem;
}
.pasummary-total{
  display: grid;
  grid-template-columns: 319px 123px 315px 245px;
  margin-top: 14px;
}
.options {
  margin-top: 6px;
  width: 105px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Accent */

  color: #186569;
}
.option-container {
  position: relative;
  bottom: -9px;
  left: 12px;
  display: flex;
  flex-direction: column;
  background: #186569;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 150px;
  height: fit-content;
}

.option {
  padding-left: 1rem;
  height: 35px;
  display: flex;
  align-items: center;
  align-items: center;
  background-color: #186569;
  color: #ffff;
  border: none;
}
.option:hover{
  padding-left: 1rem;
  height: 35px;
  display: flex;
  align-items: center;
  align-items: center;
  background-color: #ffffff;
  color: #186569;
}

.tab-item {
  margin-top: 0.5rem;
  text-align: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 6px;
}
.active-option {
  background: #ffffff;
  color: #186569;
}
.pa-participant-img img {
  object-fit: cover;
  width: 43px !important;
  height: 43px !important;
}
@media (max-width: 920px) {
  .pa-stage-tab {
    background: #ffffff;
    border: 0.5px solid #aeb8c9;
    border-radius: 6px;
    width: 100%;
    justify-self: center;
    height: 40px;
    margin: auto;
    justify-content: center;
    display: flex;
    gap: 1rem;
  }
  .pasummary-total{
    display: grid;
    grid-template-columns: 0%;
  }
  .none{
    display: none;
  }
}
