.top-section {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 20px;
}
.representative-card {
  height: fit-content;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.top-left-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.merging-component {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
}
.budget-container {
  height: 50px;

  /* background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px; */

  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-between;
  align-items: center;
}
.budget-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.budget-cash {
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.timeLine-container {
  height: 50px;

  /* background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px; */
}
.calender-container {
  height: 335px;
  width: 100% !important;
  max-width: 100% !important;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
}
.representative-container {
  padding-bottom: 1rem;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
}
.representative-address {
  height: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  display: flex;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}
.edit-representative {
  border: none;
  background: none;
}
.add-client-representative {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #064b4f;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  align-self: flex-end;
  justify-self: end;
  margin-right: 20px;
  margin-bottom: 10px;
}
.delete-circle-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ff5252;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  align-self: flex-end;
  justify-self: end;
  margin-right: 20px;
  margin-bottom: 10px;
}
.timeLine-container {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
}
.project-date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project-signed-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-date-timeLine {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.project-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}

.react-calendar {
  border: none !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */
  width: fit-content;
  color: #186569;
}
.assigned-team-container {
  margin-top: 15px;
  margin-right: 8px;
  min-height: 400px;
  max-height: fit-content;

  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
}
.assigned-team-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 180px 180px 180px;
  justify-content: center;
  height: fit-content;
}
.slider-btn {
  cursor: pointer;
}
.assigned-team-member {
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ececec;
  gap: 15px;
}
.slider-assigned-team {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.assigned-team-controller {
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.participant-img {
  height: 50px;
  margin-left: 10px;
  align-self: center;
  margin-top: 10px;
}
.assigned-participant-img {
  align-self: center;
  margin-top: 10px;
  width: 58px;
  height: 58px;
}

.participant-img img {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
}
.participant-avatars {
  object-fit: cover;
  width: 100% !important;
  height: 100% !important;
  /* Primary */

  border: 1.5px solid #064b4f;
}
.InvolvedSolution-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-right: 8px;
  height: fit-content;

  background: #ffffff;
  /* Neutral 1 */

  border-radius: 6px;
  margin-bottom: 40px;
}
.involved-team-container {
  display: grid;
  grid-template-columns: 225px 225px 225px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.involved-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
.card-top-section-involved-solution {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.edit-option {
  cursor: pointer;
  border: none;
  background: none;
}
.option-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 65px;
  align-self: flex-end;
  left: 100px;
  top: -145px;
  z-index: 1;
  width: 100px;
  /* Neutral 2 */

  background: #e2e7f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}
.option-modal-controller {
  height: 32px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 5px;
  cursor: pointer;
}
.option-divider {
  width: 99px;
  height: 0px;
  left: 1009px;
  top: 948px;

  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
}
.involvedTeam-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.inner-items-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.inner-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.items-number {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.items-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.viewMore-btn {
  border: none;
  color: white !important;
  width: 179px;
  height: 40px;

  /* Accent */

  background: #186569;
  border-radius: 6px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assign-personal-btn {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #064b4f;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  right: -91%;
  top: 8px;
  bottom: 0%;
}
.responsive-card {
  padding-top: 5px;
  padding-bottom: 5px;
  background: #ffffff;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  height: fit-content;

  gap: 1rem;
}

.member-profile {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Lc {
  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  padding: 25px;
  display: flex;
  flex-direction: column;
}
.Lc-information {
  align-self: center;

  /* Text */

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.info-section {
  gap: 10px;

  display: flex;
  flex-direction: column;
}
.s-label {
  align-items: end;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.s-value {
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
@media (max-width: 768px) {
  .top-section {
    display: flex !important;
    flex-direction: column;
  }

  .representative-address {
    height: 80px;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .assigned-team-grid {
    gap: 10px;
    grid-template-columns: 130px 130px 130px;
    height: max-content;
  }

  .assigned-team-member {
    gap: 20px;
    background: #ffffff;
    /* Neutral 2 */

    border: 1px solid #e2e7f0;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    height: 130px;
    width: 100%;
  }
  .assign-personal-btn {
    right: -82%;
  }

  .involved-team-container {
    grid-template-columns: 225px;
  }
  .no {
    display: none;
  }
}
.attach-document {
  height: 69px;
  background: #353945;
  border: 1px solid #ececec;
  border-radius: 6px;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.105em;

  color: #ffffff;
}
.attach-document div svg {
  margin-bottom: -5px;
}
.attach-document-active-link {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  height: 70%;
}
.attach-document-active-link:hover {
  /* border-radius: 1rem;
  transition-property: " border-radius";
  transition-duration: 1s;
  width: 60%;
  transition-property: " width";
  transition-duration: 2s;
  background: #4b5264;
  transition-property: "background";
  transition-duration: 1s;

  text-decoration-line: underline;
  transition-property: "text-decoration-line";
  transition-duration: 2s; */
}
