.container {
  background-color: #eff2f3;
  width: 100%;
  height: 100vh;
}
.faq-videos-container {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 1rem;
  width: 100%;
  min-height: 92%;
  padding-top: 20px;
  padding-bottom: 40px;

  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.faq-top-section {
  display: flex;
  flex-direction: column;
}
.faq-search-bar {
  width: 536px;
  height: 48px;

  background: #eff2f3;
  border-radius: 4px;

  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.search-input {
  height: 100%;
  width: 95%;
  outline: none;
  border: none;
  background: #eff2f3;
}
.video-container {
  margin-top: 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: 230px 230px 230px 230px;
}
.faq-pagination-container {
  /* / align-self: flex-end; */
}
.faq-main-content {
  display: grid;
  grid-template-columns: 65% 30%;
  gap: 25px;
}
.top-questions {
  margin-top: 40px;
  height: 500px;
  /* overflow-y: auto; */
  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;
}

.top-question-container {
  height: 80%;
  margin-left: 25px;
  margin-right: 25px;
  overflow-y: scroll;
}
.top-question-container::-webkit-scrollbar {
  width: 5px;
}

/* Track */
/* .top-question-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} */

/* Handle */
.top-question-container::-webkit-scrollbar-thumb {
  background: #064b4f;
  border-radius: 1rem;
}
.faq-main-right-section {
  height: fit-content;
  margin-top: 40px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;
}
.total-questions-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 265px;
  height: 100px;
}
.faq-main-right-section-text-font {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #ffffff;
}
.faq-main-right-section-number-font {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #ffffff;
}
.total-q-red-linear {
  background: linear-gradient(180deg, #bb5022 0%, #5a1818 100%);
}
.total-q-green-linear {
  background: linear-gradient(180deg, #4faeb3 0%, #064b4f 100%);
}
.total-q-blue-linear {
  background: linear-gradient(180deg, #228dbb 0%, #183a5a 100%);
}
.faq-total-question-container {
  height: 80%;
  background: #ffffff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
}
.total-question-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
  border-bottom: 2px solid #e2e7f0;

  width: 250px;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  padding-bottom: 8px;
  margin-bottom: 1rem;
}
.questions-container {
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid #e2e7f0;
}
.faq-question-txt {
  width: 90%;
}
.faq-controller {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}
.parent-questions-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.video-container {
  position: relative;
}
.title-label {
  position: absolute;
  z-index: 9;
  margin-top: 5px;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #ffffff;
}
@media (max-width: 768px) {
  .faq-search-bar {
    width: 100%;
  }
  .video-container {
    grid-template-columns: 100%;
  }
}
