.payment-collection-container{
box-sizing: border-box;
width: 100%;
min-height: 425px;
background: #FFFFFF;
border: 1px solid #AEB8C9;
border-radius: 6px;
}
.price-accordion-container{
    display: grid;
    grid-template-columns: 45% 45%;
    gap:20px;
   justify-content: center;
}
.finance-input-field{
width: 319px;
height: 40px;
background: #EFF2F3;
border-radius: 4px;
border: none;
font-size: 12px;
}
.finance-table-container{
    overflow-x: scroll;
}
.finance-table-container::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.finance-table-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}