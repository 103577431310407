.pi-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  color: #575757;
}
.pi-modal-header {
  padding: 6px 16px;
}
.pi-modal-content {
  width: 518px;
  background-color: #fff;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
hr {
  margin-top: 0;
}
.pi-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 94%;
}
.pi-inputs input,
textarea {
  border-radius: 6px;
  border: 1px solid #eff2f3;
  background-color: #eff2f3;
  width: 249px;
  height: 32px;
  margin: 0 8px;
}
textarea {
  height: 140px;
}
section.pi-inputs-modal {
  margin: 12px;
  display: flex;
  justify-content: space-between;
}
form.pi-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pi-modal-footer {
  margin-top: 24px;
}
.pi-modal-footer > button {
  width: 112px;
  height: 32px;
  margin: 0 12px;
  border-radius: 25px;
  border: none;
  font-size: 14px;
}
.pi-modal-footer > button:first-of-type {
  background-color: #aeb8c9;
}
.pi-modal-footer > button:last-of-type {
  background-color: #064b4f;
  color: white;
}
@media (max-width: 920px) {
  .pi-modal-content {
    width: 100%;
    background-color: #fff;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pi-inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: auto;
    margin-top: 32px;
  }
}
