.search-btn {
  background: #064b4f;
  border-radius: 25px;
  width: 102px;
  height: 28px;
  color: white;
  text-align: center;
}
.search-btn p {
  font-weight: 500;
  font-size: 16px;
}
.filter-btn {
  outline: none;
  border: none;
}
.utilities {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.filter-search {
  display: flex;
  width: 261px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
  padding-left: 1px;
  padding-right: 1px;
}
.search {
  outline: none;
  border: none;
}
