
.profile {
padding: 1.2rem;
}
.profile, .account {
    background-color: #fff;
    width: 80%;
    margin: auto;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 6px;
}
#image{
    width: 134px;
    height: 134px;
    position: relative;
    border: 2px solid #064B4F;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 1.5rem;
}
.signature-image{
    width: 300px !important;
    height: 300px !important;
}
#image>img{
    width: 124px !important;
    height: 124px;
    border-radius: 50%; 
}
.account > .header {
    border-bottom: 1px solid #AEB8C9;
    width: 100%;
}
.header {
    padding: 0.25rem;
}
.header>h4 {
    color: #064B4F;
    font-size: 24px;
    text-align: center;
}

h5 {
    letter-spacing: 10.5%;
    font-size: 24px;
    font-weight: bold;
}
p{
    letter-spacing: 10.5%;
    font-size: 14px;
    font-weight: 400;
}

#camera--icon {
    width: 40px;
    height: 40px;
    background-color: #EFF2F3;
    border-radius: 50%;
    position: absolute;
    top: 94px;
    right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

section {
    margin: 0.5rem;
}
.name {
    display: flex;
}
form {
    padding: 0rem;
    width: 100%;
}