.product-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 12px;
}
.product-card {
  box-sizing: border-box;

  height: 137px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
}
.solution-controller {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 10px;
}
.solution-controller svg {
  cursor: pointer;
}
.sub-solution {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sub-solution-controller svg {
  cursor: pointer;
}
.product-card-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.product-card-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.product-team {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #353945;
}
.product-quantity {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.product-type {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.product-divider {
  margin-top: 10px;
  border-left: 1px solid #ececec;
  height: 80px;
}
.pmr-name {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.pmr-designation {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.product-card-pmr {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  color: #ffffff;
  background: #064b4f;
  border-radius: 1rem;
  width: 117px;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
}
@media (max-width: 920px) {
  .product-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;
  }
  .add-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.105em;
    color: #ffffff;
    background: #064b4f;
    border-radius: 1rem;
    width: 117px;
    height: 32px;
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: none;
    margin-top: 20px;
  }
}
@media (max-width: 1220px) {
  .product-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;
  }
  .add-btn {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.105em;
    color: #ffffff;
    background: #064b4f;
    border-radius: 1rem;
    width: 117px;
    height: 32px;
    padding-left: 3px;
    padding-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border: none;
    margin-top: 20px;
  }
}
