.smManufacturer-card-container {
  display: grid;
  grid-template-columns: 270px 270px 270px 270px;
  gap: 20px;
  justify-content: center;
}
.smManufacturer-card {
  box-sizing: border-box;

  height: 68px;

  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.batch-detail-card {
  box-sizing: border-box;

  height: 90px;

  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.participantManufacturer-img {
  height: 41px;
  margin-left: 10px;
  align-self: center;
  margin-top: 10px;
  width: 41px;
}
.summaryManufacturer-row {
  display: grid;
  grid-template-columns: 1fr repeat(8, 2fr);
  text-align: center;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.summaryManufacturer-row-BcStage {
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr);
  text-align: center;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.supplier-summaryManufacturer-row-BcStage {
  display: grid;
  grid-template-columns: 1fr repeat(6, 2fr);
  text-align: center;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.supplier-summaryManufacturer-row {
  display: grid;
  grid-template-columns: 1fr repeat(9, 2fr);
  text-align: center;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.summaryManufacturer-approve-row {
  display: grid;
  grid-template-columns: 1fr repeat(8, 2fr);
  text-align: center;
  height: fit-content;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.summarymanufacturer-header {
  text-align: center !important;
  border: none !important;
  box-shadow: none !important;
  padding: 14px;
  display: grid;
  grid-template-columns: 1fr repeat(8, 2fr);
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.summarymanufacturer-header-BcStage {
  text-align: center !important;
  border: none !important;
  box-shadow: none !important;
  padding: 14px;
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr);
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.supplier-summarymanufacturer-header {
  text-align: center !important;
  border: none !important;
  box-shadow: none !important;
  padding: 14px;
  display: grid;
  grid-template-columns: 1fr repeat(9, 2fr);
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.supplier-summarymanufacturer-header-BcStage {
  text-align: center !important;
  border: none !important;
  box-shadow: none !important;
  padding: 14px;
  display: grid;
  grid-template-columns: 1fr repeat(6, 2fr);
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.approval-btn {
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #064b4f;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}

.decline-btn {
  width: -moz-fit-content;
  width: fit-content;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #ec4a4a;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}
.comment-decline-btn {
  width: -moz-fit-content;
  width: 174px;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #ec4a4a;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}
.po-smManufacture-card {
  box-sizing: border-box;
  height: 96px;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  /* border-radius: 6px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.po-comment-container {
  position: relative;
  top: 4px;
  left: 140px;
  display: flex;
  flex-direction: column;
  background: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 208px;
  height: 145px;
  border: 1px solid #e2e7f0;
}
.pa-comment-container {
  position: relative;
  top: 4px;
  left: 325px;
  display: flex;
  flex-direction: column;
  background: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  width: 208px;
  height: 145px;
  border: 1px solid #e2e7f0;
}
.po-comment-section {
  width: 176px;
  height: 69px;
  background: #ffffff;
  border: 1px solid #e2e7f0;
  border-radius: 6px;
}
.supplier-poStage-row {
  display: grid;
  grid-template-columns: 1fr repeat(6, 2fr);
  text-align: center;
  min-height: 70px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.poStage-row {
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr);
  text-align: center;
  min-height: 70px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.orderStage-row {
  display: grid;
  grid-template-columns: 1fr repeat(7, 2fr);
  text-align: center;
  min-height: 70px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.supplier-orderStage-row {
  display: grid;
  grid-template-columns: 50px 100px 180px 120px 120px 120px;
  text-align: center;
  min-height: 70px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.po-summary-table {
  max-height: 455px;
  background: #ffffff;
  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}
.supplier-po-summary-table {
  min-height: 200px;
  background: #ffffff;
  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}
.po-summary-table::-webkit-scrollbar {
  width: 5px;
}
.po-summary-table::-webkit-scrollbar-track {
  background: #f7f5f5;
  border-radius: 3px;
}
.po-summary-table::-webkit-scrollbar-thumb {
  background: #186569;
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}
.payment-text {
  height: 12px;
  left: 1051px;
  top: 175px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.payment-text-bold {
  height: 16px;
  left: 1157px;
  top: 173px;

  /* Body Large */

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.batch-card-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 350px 350px 350px;
  gap: 12px;
}
.batch-detail {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.batch-date {
  height: 9px;
  left: 708px;
  top: 308px;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.105em;
  text-align: center;

  /* Text */

  color: #353945;
}
.batch-card {
  box-sizing: border-box;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  border-radius: 8px;
}
.batch-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.105em;
  color: #353945;
}
.batch-detail-container {
  border: 1px solid #e2e7f0;
  height: -moz-fit-content;
  height: fit-content;
}
.batch-detail-header {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.105em;
  height: 30px;
  color: #186569;
  padding-left: 1rem;
  align-items: center;
  display: flex;
  border-bottom: 0.5px solid #e2e7f0;
  justify-content: space-between;
}
.batch-supplier {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  margin-top: 12px;

  /* Accent */

  color: #186569;
}
.batch-item-description {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  margin-left: 35px;
  margin-bottom: 5px;

  /* Text */

  color: #353945;
  display: flex;
  align-items: center;
  gap: 3px;
}
.pa-label {
  display: none;
}

@media (max-width: 920px) {
  .smManufacturer-card-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
    justify-content: center;
  }
  .summarymanufacturer-header {
    display: none;
  }
  .summaryManufacturer-row {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
    height: fit-content;
    align-items: center;
    border: 1px solid #e2e7f0;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.105em;
    color: #353945;
    margin-top: 20px;
  }
  .summaryManufacturer-approve-row {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
    height: fit-content;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.105em;
    color: #353945;
    margin-top: 20px;
  }
  .label-container {
    display: grid;
    /* gap:50px; */
    grid-template-columns: 140px 160px;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .pa-label {
    display: block;
    width: 150px;
    color: #aeb8c9;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
  }
  .poStage-row {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
    height: -moz-fit-content;
    min-height: fit-content;
    align-items: center;
    border: 1px solid #e2e7f0;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.105em;
    color: #353945;
    margin-top: 20px;
  }
  .orderStage-row {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
    height: -moz-fit-content;
    min-height: fit-content;
    align-items: center;
    border: 1px solid #e2e7f0;
    box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.105em;
    color: #353945;
    margin-top: 20px;
  }
  .sourcing-postage-big-btn {
    width: 100%;
    height: 52px;
    line-height: 16px;
    letter-spacing: 0.105em;
    background: #353945;
    color: #ffffff;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 26px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #353945;
  }
  .summarymanufacturer-header {
    display: none;
  }
  .pa-comment-container {
    position: relative;
    top: 4px;
    left: 0px;
    display: flex;
    flex-direction: column;
    background: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    width: 208px;
    height: 145px;
    border: 1px solid #e2e7f0;
  }
}
