.main-content {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 15px;
  margin-top: 50px;
}
.task-rma-card {
  display: grid;
  grid-template-rows: 415px;
  gap: 15px;

}
.Purchase-Order-Status-card {
  height: 845px;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
}
.task-card {
  width: 100%;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  height: 408px;
}
.rma-card {
  width: 100%;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
}
.home-card-label {
  margin-top: 14px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.rma-task-container {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.order-project-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #aeb8c9;
}
.order-project-name {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.order-project-label {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.order-project-description {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  color: #353945;
}
.task-rma-controller {
  display: flex;
  align-items: center;
}
.task-rma-controller button {
  outline: none;
  border: none;
  background-color: white;
}
.purchase-order-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 10px;
}
.purchase-items-card {
  background: #ffffff;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-left: 20px;
}
.project-client{
box-sizing: border-box;
width: 100%;
height: 123px;
background: linear-gradient(244.95deg, #186569 -21.26%, #52FFAC 120.14%);
border: 0.5px solid #AEB8C9;
box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
border-radius: 6px;
}
.project-client-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  padding: 30px;
}
.home-number{
  color: #fff;
  font-size: 24px;
  font-style: normal;
font-weight: 400;

line-height: 15px;
letter-spacing: 0.105em;

}
.home-text{
  color: #fff;
  font-size: 13px;
  font-style: normal;
font-weight: 400;

line-height: 15px;
letter-spacing: 0.105em;

}
.forex-card{
box-sizing: border-box;
width: 100%;
height: 87px;
background: #FFFFFF;
border: 0.5px solid #AEB8C9;
box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
display: flex;
justify-content: space-between;
align-items: center;
padding: 30px;
border-radius: 6px;
}
.forex-text{
  color: #AEB8C9;
  font-size: 13px;
  font-style: normal;
font-weight: 400;

line-height: 15px;
letter-spacing: 0.105em;

}
.forex-number{
  color: #186569;
  font-size: 28px;
  font-style: normal;
font-weight: 400;
line-height: 15px;
letter-spacing: 0.105em;

}
.dashboard-card{
box-sizing: border-box;
width: 100%;
height: 325px;
background: #FFFFFF;
border: 0.5px solid #AEB8C9;
box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
border-radius: 6px;
}
.no-task-dashboard-card{
box-sizing: border-box;
width: 100%;
height: 411px;
background: #FFFFFF;
border: 0.5px solid #AEB8C9;
box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
border-radius: 6px;
}
.topsupplier-inventory-tool{
  display: grid;
  grid-template-columns: 20% 39% 39%;
  margin-top: 20px;
  gap: 20px;
}

.forex-payment{
  display: grid;
  grid-template-columns:  40% 59%;
  margin-top: 20px;
  gap: 20px;
}
.project-select-option{
width: 235px;
height: 32px;
background: #EFF2F3;
border-radius: 4px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #AEB8C9;
}
.dashboard-title{

font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 21px;
letter-spacing: 0.105em;
color: #353945;
}
.topsuppliers{

font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.105em;
color: #AEB8C9;
padding-top: 17px;
padding-left: 37px;
}
.topsuppliers-container{
  display: grid;
  gap: 5px;
}
.title-container-payment{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task-order-name{
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 15px;
letter-spacing: 0.105em;
color: #353945;
}
.tasks-dashboard-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 330px;
}
.rma-task-container {
  margin-left: 23px;
  margin-right: 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.task-icons-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1.5rem;

  border-bottom:0.5px solid #AEB8C9;
}
.forex-bar{
  box-sizing: border-box;
    display: flex;
    height: 367px !important;
    width: 818px  !important;
    justify-content: center ;
}
@media (max-width: 920px) {
  .main-content {
    display: grid;
    grid-template-columns: 100%;
    gap: 15px;
    margin-top: 50px;
  }
  .topsupplier-inventory-tool{
    display: grid;
    grid-template-columns: 100%;
    margin-top: 20px;
    gap: 20px;
  
  }
  .forex-payment{
    display: grid;
    grid-template-columns:  100%;
    margin-top: 20px;
    gap: 20px;
  }
  .mobile-payment{
    display: grid;
  }
  .title-container-payment{
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .dashboard-card{
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border: 0.5px solid #AEB8C9;
    box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
    border-radius: 6px;
    }
    .payment-count{
      display: none;
    }
    .forex-card{
      box-sizing: border-box;
      width: 100%;
      height: 87px;
      background: #FFFFFF;
      border: 0.5px solid #AEB8C9;
      box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px;
      border-radius: 6px;
      }
      .project-client {
        box-sizing: border-box;
        width: 100%;
        height: 123px;
        background: linear-gradient(244.95deg, #186569 -21.26%, #52FFAC 120.14%);
        border: 0.5px solid #AEB8C9;
        box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
        border-radius: 6px;
    }
    .forex-bar {
      box-sizing: border-box;
      display: flex;
      height: 100% !important;
      width: 100%  !important;
      justify-content: center;
  }
  .no-task-dashboard-card {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 0.5px solid #AEB8C9;
    box-shadow: 0px 1px 4px rgba(6, 75, 79, 0.2);
    border-radius: 6px;
}
}
