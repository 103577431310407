.stock-table-header{
    width: 100%;
    height: 54px;
    background: #E2E7F0;
    color: #353945;
    font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-align: left;
}
.stock-table-row{
    width: 100%;
    height: 54px;
    background: #fff;
    color: #353945;
    font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
border-bottom: 1px solid #E2E7F0;
text-align: left;
}
.stock-img{
width: 100px !important;
width: 100px !important;
border-radius: 0px;
}
.stock-header-container{
    width: 100%;
    height: 70px;
    background: white;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 15px;
}
.inventory-header-container{
    width: 100%;
    height: 70px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 100px;
    padding-right: 25px;
}
.stock-btn{
    width: fit-content;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.105em;
    background: #064b4f;
    border-radius: 25px;
    color: #ffffff;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
}
.add-purchase-image{
    width: 300px !important;
    height: 200px !important;
    border-radius: 0px!important;
}
.signature-image{
    width: 90px !important;
    height: 45px !important;
    border-radius: 0px!important;
}
#request--icon {
    width: 200px;
    height: 200px;
    background-color: #EFF2F3;
    border-radius: 50%;
    position: relative;
    top: 0px;
    right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
#request--icon input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  
.inventory-search-input{
    width: 246px;
height: 32px;
    border: 1px solid #E2E7F0;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
color: #353945;
}
.items-counter{
width: 196px;
height: 54px;
background: #186569;
border-radius: 6px;
display: grid;
justify-content: center;
color:#ffffff;
}
.stock-project-container{
    display: grid;
    grid-template-columns: 348px 348px 348px;
    gap:24px
}
.stock-project-card{
box-sizing: border-box;
width: 348px;
height: 154px;
left: 197px;
top: 150px;
background: #FFFFFF;
border: 1px solid #ECECEC;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
border-radius: 6px;
cursor:pointer
}
.stock-project-name{
width: 232px;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.105em;
color: #353945;

}
.stock-project-sub-name{
width: 38px;
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
letter-spacing: 0.105em;
color: #AEB8C9;

}
.tool-item-description{
width: 100%;
min-height: 402px;
background: #FFFFFF;
padding-left: 84px;
padding-right: 84px;
padding-top: 32px;
padding-bottom: 48px;

}
.tool-item-image{
width :534px;
height:283px;
border-radius: 0px !important;
border: 1px solid #186569;

}
.tool-item-status{
padding-left: 12px;
padding-right: 12px;
padding-top: 5px;
padding-bottom: 5px;
background: #52FFAC;
border-radius: 6px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #353945;
}
.tool-item-title{
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: #353945;
}
.tool-header-container{
    width: 100%;
    height: 70px;
    background: white;
    display: flex;
    align-items: center;
    padding-left: 84px;
    gap:24px
}
.register-checkbox-group {
    display: flex;
    gap: 1rem;
    height: 15px;
    color: #186569;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.105em;
    color: #186569;
    min-width: 3rem;
    margin-bottom: 18px;
    margin-top: 13px;
    justify-content: center;
}
.request-option-container{

position: absolute;
top: 146px;
right: 510px;
display: flex;
flex-direction: column;
width: fit-content;
height: 64px;
background: #FFFFFF !important;
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
border-radius: 4px;
}
.item-option-container{

position: absolute;
top: 146px;
right: 430px;
display: flex;
flex-direction: column;
width: fit-content;
height: 64px;
background: #FFFFFF !important;
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
border-radius: 4px;
}
.request-option{
    padding-left: 1rem;
    padding-right: 1rem;
    height: 35px;
    display: flex;
    align-items: center;
    align-items: center;
    background-color: #fff;
 font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;

color: #064B4F;
    border: none;
}
.request-option-muted{
    padding-left: 1rem;
    height: 35px;
    display: flex;
    align-items: center;
    align-items: center;
    background-color: #fff;
 font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;

color: #AEB8C9;
    border: none;
}
.stock-checkmark {
    height: 23px;
    width: 12px;
    background-color: #eee;
    border: 2px solid #186569;
    cursor: pointer;
}
.stock-dialog-content{
    background: #ffffff;
    border-radius: 6px;
    width: 447px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
}
.store-amount-input{
box-sizing: border-box;
width: 48px;
height: 32px;
border: 1px solid #E2E7F0;
}
.stock-table-header-text{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #353945;

}
.stock-checkbox-batch{
width: 20px;
height: 20px;
background: #D9D9D9;
border-radius: 2px;
}
.batch-header-container {
    width: 100%;
    height: 70px;
    background: white;
    display: flex;
    gap:20px;
    align-items: center;
    padding-left: 100px;
    padding-right: 25px;
}
.stock-decline{
    width: fit-content;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.105em;
    background: #FF5252;
    border-radius: 25px;
    color: #ffffff;
    border: none;
    padding-left: 20px;
    padding-right: 20px;


}
.stockAccordionCard {
    background-color: #fff;
    border: none;
}
.incomingTitle {
    background-color: #fff;
    border: none;
    width: 100%;
}
.stock-nav-link{
    display: block;
    text-align: center;
    color: #064b4f;
    text-decoration: none;
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
/* .stock-nav-link.active{
    display: block;
    padding: 0.2rem 1rem;
    color: #064b4f;
    text-decoration: none;
    font-size: 14px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    border: 1px solid #064b4f;
    border-radius: 25px;
} */

 .stock-nav-link.active{
    background-color: #fff;
    border: 2.5px solid #186569;
    color: #495057;
    border-radius: 25px;
    text-align: center;
}
.stock-nav-link-index{
    color: #186569;
    margin-bottom: 2px;
    margin-top: 2px;
    padding: 3px 12px 5px;
    border-radius: 25px;
    font-size: 14px;
    text-align: center;

}
.btn-secondary {
    color: #186569; 
    background-color: #fff !important;
    border-color:#fff !important;
}
.btn-secondary:hover {
    color: #186569; 
    background-color: #fff !important;
    border-color:#fff !important;
}
.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
    color: #186569;
    background-color: #565e64;
    border-color: #51585e;
}
.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0px !important;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}