.payable-container {
  box-sizing: border-box;
  width: 100%;
  min-height: 425px;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  border-radius: 6px;
}

.payable-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 20px;
  justify-content: center;
}
.payable-export-button {
  display: flex;
  width: 160px;
  margin: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 30px;
  background-color: #064b4f;
  padding: 5px;
}
.text {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.Payable-Table-Container {
  margin: 20px;
}

.start-align {
  justify-content: start !important;
}

.download-btn {
  border: 1.5px solid #064b4f;
  border-radius: 25px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background-color: #064b4f;

  /* Primary */

  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 20px;
  padding-right: 20px;
  height: 32px;
}
.prg {
  font-size: 14px;
  color: #353945 !important;
  letter-spacing: 0.105em;
  font-weight: 400;
}
.custom-select {
  width: 160px;
  border: 1px solid #064b4f;
  border-radius: 4px;
  padding: 5px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.table-head {
  background-color: rgba(86, 184, 183, 0.2);
  color: #304569;
}
.prg-payableHistory {
  font-size: 14px;
  letter-spacing: 0.105em;
  font-weight: 400;
  background-color: #064b4f;
  color: #ffffff;
  margin: "0 !important";
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  width: 160px;
  border: 1px solid #064b4f;
  border-radius: 4px;
  padding: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
