img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  height: 54px;
  border-bottom: 1px solid #e2e7f0;
}
.profile {
  display: flex;
  gap: 13px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #383945;
}
.supplier-nav-bar {
  margin-left: 106px;
  width: 95%;
}
.main-nav-bar {
  margin-left: 0px;
  width: 100%;
}
/* ---Sidebar css */
.text-primary {
  color: #5a8dee !important;
}
.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}
.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar {
  border-radius: 5px;
}
.sidebar {
  width: 280px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #186569;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 2;
  transition: 0.5s;
}
.sidebar.active {
  left: 0;
}
.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}
.sd-body {
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}
.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}
.sd-body ul li {
  list-style: none;
  margin-bottom: 8px;
}
.sd-body ul li .sd-link {
  display: inline-block;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}
.mobile-side-bar {
  display: none;
}
.mobile-submenu {
  color: #186569 !important;
}

.mobile-submenu:active {
  background-color: #186569;
  color: #ffffff;
  transition: 1s;
}
.mobile-submenu-list {
  background-color: #fff;
  color: #186569;
  display: flex;
  align-items: center;
  gap: 77px;
}
.sub-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: #186569;
}
@media (max-width: 920px) {
  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 31px;
    height: 54px;
    border-bottom: 1px solid #e2e7f0;
    width: 100%;
  }
  .layout {
    grid-template-columns: 100%;
  }
  .side-nav {
    display: none;
  }
  .supplier-nav-bar {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .supplier-filter {
    display: flex;
    width: 100px;
    height: 32px;
    background: #ececec;
    border: 1px solid #ececec;
    border-radius: 6px;
    align-items: center;
    justify-content: space-between;
    padding-left: 1px;
    padding-right: 1px;
    color: #000;
  }
  .supplier-search {
    width: 70px;
  }
  .page {
    min-height: 100vh;
    overflow-y: hidden;
    background: #eff2f3;
    padding-top: 0px;
    padding-bottom: 2rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobile-side-bar {
    display: block;
  }
}
