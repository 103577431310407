.supplier-page-container{
  display: grid;
}
.supplier-menu-container{
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}
.supplier-menu-card{
  background-color: white;
    height: 180px;
    display: grid;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.08);
    flex-grow: 1;
    flex-basis: 48.5%;
}
.every-index-page{
  display: grid;
  margin-left: 13%;
}
.every-detail-page{
  display: grid;
  margin-left: 12%;
}
.detail-address-container{
  display: grid;
  grid-template-columns: 63% 35%;
  gap:20px
}
.card-supplier {
  box-sizing: border-box;
  height: 118px;
  top: 149px;
  background: #ffffff;
  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
 
}
.h5-supplier {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945;
}
.small {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  margin-top: -15px;

  /* Neutral 1 */

  color: #aeb8c9;
}
.name-supplier {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  margin-top: 20px;
}
.importbtn {
  box-sizing: border-box;
  width: 113px;
  height: 23px;
  border: 1px solid #353945;
  border-radius: 25px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.105em;
}
.modal-content-md {
  background-color: #fefefe;
  margin-left: 58%;
  height: 100%;
  border: 1px solid #888;
  width: 42%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.modal-content-lg {
  background-color: #fefefe;
  margin-left: 0%;
  height: 100%;
  border: 1px solid #888;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.mapping-btn {
  width: 82px;
  height: 26px;
  border: 1.5px solid #186569;
  border-radius: 25px;
}
.btn-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #186569;
  text-align: center;
}
.mapping-card-container {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  left: 342px;
  top: 149px;

  background: #ffffff;
  /* Neutral 1 */
  border: 1px solid #aeb8c9;
  border-radius: 6px;
}
.mapping-card-header {
  width: fit-content;
  height: 28px;
  /* H4 */

  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.mapping {
  width: 142px;
  height: 47px;
  margin-top: 30px;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 15%);
}

.mapping-text {
  height: 19px;

  /* H5 */

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.supplier-product-rounded {
  width: fit-content;
  height: 21px;
  background: #e2e7f0;
  border-radius: 25px;
  padding: 4px;
}
.supplier-product-rounded-text {
  width: fit-content;
  height: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.supplier-product-plus {
  box-sizing: border-box;
  border: 1.5px solid #064b4f;
  border-radius: 25px;
  margin-top: 10px;
  width: 40px;
}
.supplier-product-edit {
  box-sizing: border-box;
  background-color: #064b4f;
  border-radius: 25px;
  margin-top: 10px;
  width: 40px;
}
.supplier-product-detail-new {
  width: 164px;
  height: 32px;
  background: #ffffff;
  border: 0.5px solid #064b4f;
  border-radius: 25px;
}
.nav-tabs .nav-link {
  margin-bottom: 0px;
  background: 0 0;
  border-radius: 40px;
  font-size: 13px;
  color: #186569;
  border-bottom: 30px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border: 2.5px solid #186569;
  margin-bottom: 2px;
  margin-top: 2px;
  padding: 3px 12px 5px;
}
.card-information {
  width: 300px;
}
.supplier-card-address {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: 231px;
  width: 100%;
}
.supplier-card-representative {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: fit-content;
  width: 100%;
}
.products_padding {
  padding-left: 100px;
}
.supplier-select-option {
  width: 197px;
  background: #ececec;
  border: 1px solid #ececec;
  border-radius: 6px;
  outline: none;
  height: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #353945;
}
.supplier-representative {
  background: #ffffff;
  /* Neutral 2 */
  border: 1px solid #e2e7f0;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.supplier-address-container {
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
}
.supplier-address-container-edit {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: baseline;
}
.address-divider {
  width: 0px;
  height: 100px;
  border: 1px solid #aeb8c9;
}
.supplier-product-container {
  height: 237px;
}
.manufacturer-product-container {
  height: 231px;
}
.supplier-bank-header {
  display: grid;
 grid-template-columns: 15.25% 15.25% 15.25% 15.25% 15.25% 15.25% 7.25%;
}
.supplier-bank-body {
  display: grid;
grid-template-columns: 15.25% 15.25% 15.25% 15.25% 15.25% 15.25% 7.25%;
align-items: center;

}
.supplier-remove-btn {
  width: fit-content;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #ff5252;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 67px;
  padding-right: 67px;
  cursor: pointer;
}
.supplier-remove-btn:hover {
  width: fit-content;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #fff;
  border-radius: 25px;
  color: #ff5252;
  border: none;
  padding-left: 67px;
  padding-right: 67px;
  cursor: pointer;
  border: 1px solid #ff5252;
}
.checkmark {
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 2px solid #186569;

  cursor: pointer;
}
.supplier-input-labels {
  display: flex;
  flex-direction: column;
  height: max-content;
  gap: 25px;
}
.supplier-breadcrumb {
  margin-left: 106px;
  width: 95%;
  border-bottom: 1px solid #aeb8c9;
  margin-bottom: 29px;
}
.supplier-module-container {
  display: grid;
    grid-template-columns: 32% 32% 31%;
    gap: 28px;
    margin-top: 25px;
}
.representative-header{
  display: none;
}
@media (max-width: 920px) {
  .representative-header{
    display: inline;
  }
  .supplier-representative-container{
    display: flex !important;
    justify-content: space-between;
    height: 38px;
    align-items: center;

  }
  .supplier-module-container {
    display: grid;
    grid-template-columns: 303px;
    gap: 28px;
    margin-top: 25px;
  }
  .modal-content-md {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .card-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .form-card-information {
    width: 130%;
  }
  .supplier-card-representative {
    width: 100%;
  }

  .supplier-address-container {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .address-divider {
    width: 200px;
    height: 1px;
    border: 1px solid #aeb8c9;
  }
  .supplier-address-container-edit {
    display: grid;
    justify-content: center;
    gap: 20px;
    align-items: baseline;
  }
  .supplier-representative-sm {
    display: grid !important;
    grid-template-columns: 100%;
  }
  .supplier-bank-body {
    display: grid;
    grid-template-columns: 100%;
  }
  .supplier-bank-header {
    display: none;
  }
  .supplier-breadcrumb {
    margin-left: 0px !important;
    width: 100%;
    border-bottom: 1px solid #aeb8c9;
    margin-bottom: 29px;
  }
  .mapping-card-container {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    left: 342px;
    top: 149px;

    background: #ffffff;
    /* Neutral 1 */
    border: 1px solid #aeb8c9;
    border-radius: 6px;
  }
  .supplier-card-address {
    width: 100%;
  }
}
