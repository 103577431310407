.add-boq-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.select-option-create-order {
  outline: none;
  height: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  height: 30px;
  outline: none;
  border: none;
  background: #eff2f3;
  border-radius: 6px;
  width: 250px;
}

.select-option {
  width: 150px !important;
  background: #ececec;
  border: 1px solid #ececec;
  border-radius: 6px;
  outline: none;
  height: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text */

  color: #353945;
}
.option-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.select-btn {
  background: #353945;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #ffffff;
  width: 80px;
}
.add-boq-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* margin-top: 35px; */
}
.add-boq-form-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  gap: 10px;
}
.file-chooser {
  width: 250px;
  background: #eff2f3;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: right center;
  height: 32px;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* Body */
}
.file-chooser .select-option {
  width: 80% !important;
}
.multi-addBoq {
  width: 80%;
}
.file-input {
  opacity: 0; /* make transparent */
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
}
.file-chooser-group {
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}
.file-chooser-group .select-option {
  width: 80% !important;
}
.detailed-boq-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.detailed-boq-tab {
  width: 599px;
  height: 40px;

  /* Body */

  background: #eff2f3;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;

  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text */

  color: #353945;
}
.active-tab {
  width: 82px;
  height: 26px;
  left: 1076px;
  top: 86px;

  /* Accent */

  border: 1.5px solid #186569;
  border-radius: 25px;
}
.tab-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 25px;
  width: 80%;
}
.tab-contents {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  height: 43px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
}
.addSolution-text-field {
  width: 282px;
  height: 30px;
  outline: none;
  border: none;
  background: #eff2f3;
  border-radius: 4px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.sub-Solution-text-field {
  width: 250px;
  height: 30px;
  outline: none;
  border: none;
  background: #eff2f3;
  border-radius: 4px;
}
.btn-add {
  border: none;
  background: none;
  align-items: center;
  display: flex;
  align-self: end;
  /* margin-right: 60px; */
}
.solution-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.top-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pmr-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.solution-pmr-img {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
}
.solution-pmr-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.solution-pmr-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.solution-card {
  width: 85%;
  height: 54px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #353945;
  border: 1px solid #e2e7f0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.view-solution-btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
}

.download-boq:hover {
  background: #fff;
  border: 1px solid #186569;
  color: #064b4f;
}

.download-boq {
  width: 128px;
  height: 22px;
  left: 433px;
  top: 283px;

  /* Accent */

  background: #186569;
  border-radius: 3px;
  display: flex;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.105em;

  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  gap: 5px;
  cursor: pointer;
  text-decoration: none;
}
