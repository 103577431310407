.items-tab {
  margin-left: auto;
  margin-right: auto;
  width: 968px;
  height: 40px;
  left: 261px;
  top: 77px;

  background: #ffffff;

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 65px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  /* Text */
}
.active-tab-dep {
  /* transition: border 4s; */
  border: 1.5px solid #186569;
  border-radius: 25px;
}
.department-bottom-grid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 650px 425px;
  gap: 20px;
  justify-content: center;
}
.department-right-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.counter-container {
  display: grid;
  grid-template-columns: 49% 49%;
  gap: 8px;
}
.items-counter {
  height: 190px;

  background: #ffffff;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tracer-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.tracer-display-value {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.tracer-display-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.items-tracker {
  padding: 20px;
  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  top: 0px;
}
.table-header {
  padding-left: 1rem;
  width: 90%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.105em;
  display: grid;
  grid-template-columns: 10px 90px 190px 30px 50px 50px !important;
  margin-bottom: 20px;
  gap: 2rem;
  /* Text */
  margin-bottom: 1rem;
  color: #353945;
}
.tb-container {
  height: 30rem;
  overflow-y: scroll;
}
.tables-content {
  width: 590px;
  padding: 1rem;
  background: #ffffff;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: grid;
  grid-template-columns: 10px 90px 190px 30px 50px 50px !important;
  margin-bottom: 20px;
  gap: 2rem;
}
.item-description {
  width: 192px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  letter-spacing: 0.105em;
  text-justify: distribute;
  color: #353945;
}
.item-part-number {
  width: 91px;
}
.edit-item {
  background: none;
  border: none;
}
.second-row {
  display: flex;
  width: 185px;
  display: flex;
  justify-content: space-between;
}
.mini-modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  flex-direction: row-reverse;

  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  bottom: 0%; /* Stay in place */

  width: 100%; /* Full width */
  /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.mini-modal-content {
  margin-right: -13px;
  background-color: #fefefe;
  margin-left: 60%;
  height: 100%;
  border: 1px solid #888;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh; /* Full height */
  height: fit-content;
}
.item-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
  padding-right: 4rem;
}
.items-row {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 15px;
}
.description {
  width: 200px;
}
.item-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.failed-items {
  padding: 25px;
}
.sub-title {
  margin-left: 92px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.failed-items-from-container {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.failed-items-from {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.calender-container {
  width: 425px;
}
.table-row {
  display: flex;
  flex-direction: column;
}
@media (max-width: 768px) {
  .table-header {
    display: none !important;
  }
  .table-content {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    gap: 15px;
  }
  .item-description {
    width: 100%;
  }
  .department-bottom-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .items-tab {
    width: 100%;
  }
  .calender-container {
    width: 100%;
  }
}
