.project-index-page {
  display: flex;
  flex-direction: column;
}
.create-project-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  color: #ffffff;
  background: #064b4f;
  border-radius: 1rem;
  max-width: 159px;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.create-project-btn:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #064b4f;
  background: #ffffff;
  border-radius: 1rem;
  max-width: 159px;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border: 1px solid #186569;
}
.project-card-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 30px;
  /* height: 27rem; */
}
.finance-project-card-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 300px 300px 300px;
  gap: 30px;
}

.project-card {
  height: fit-content;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.finance-project-card {
  height: 200px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.project-card-content {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}
.project-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project-detail {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.project-participant {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.participant-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0.105em;
  /* Text */
  color: #353945;
  height: 90px;
  overflow: auto;
}
.participant-img {
  display: flex;
  margin-left: 10px;
}

.participant-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -0.5rem;
}
.participant-avatar img {
  object-fit: cover;
  width: 100% !important;
  height: 28px !important;
}
.participant-avatar div {
  align-self: center;
  object-fit: cover;
  text-align: center;
  width: 100% !important;
  height: 28px !important;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #606060;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  color: #ffffff;
}
.edit-project-btn {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 36px;
  width: 36px;
  outline: none;
  border-radius: 50%;
  border: none;
  color: #ffffff;
  background: #186569;
  mix-blend-mode: normal;

  margin-top: 7px;
  margin-bottom: 7px;
}
.project-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.project-start-date {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.project-date-description {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.project-card-divider {
  border-bottom: 1px solid #ececec;
}
.project-progress {
  margin-top: 5px;
}
.progress-tip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.progress {
  margin-top: 8px;
  height: 4px !important;
}
.progress-bar {
  background-color: #186569 !important;
}
@media (max-width: 1220px) {
  .project-card-container {
    grid-template-columns: 370px 370px;
    /* justify-content: center; */
  }
  .project-index-page {
    align-self: center;

    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 920px) {
  .project-card-container {
    grid-template-columns: 320px 320px;
    /* justify-content: center; */
  }
  .project-index-page {
    align-self: center;

    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 635px) {
  .project-card-container {
    grid-template-columns: 100%;
    width: 98%;
    gap: 10px;
    /* justify-content: center; */
  }
  .project-card-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .page {
    padding-left: 15px;
    padding-right: 15px;
  }
}
