.breadCrampLogNav {
  color: #575757;
  margin-left: 40px;
}

.rmaHeading {
  text-align: center !important;
  margin-top: 15px !important;
}
.rmaValue {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.rmaUserName {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.105em;
  text-align: left;
  padding-left: 20px;
  color: #186569;
}

.rmaStatusHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statusName {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex !important;
  justify-content: flex-start !important;
  color: #353945c5 !important;
  min-width: 120% !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  margin-top: 13px !important;
}
.rmaStatusLabel {
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.105em;
  color: #aeb8c9;
  font-size: "14px";
  margin-top: 20px !important;
  margin-left: 15px !important;
}
.profilePositionTitle {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  letter-spacing: 0.105em !important;
  color: #aeb8c9 !important;
}
.logisticsRmaProfile {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  color: #353945;
  text-align: center;
}
.rma_missed_item_avatar {
  margin-top: -7px;
}
.rmaBatchInfo {
  border: "1px solid #AEB8C9";
  overflow-y: scroll;
  height: 260px;
}
.rmaBatchInfo::-webkit-scrollbar {
  width: 5px;
}
.rmaBatchInfo::-webkit-scrollbar-track {
  background: #f7f5f5;
  border-radius: 3px;
  margin-top: -16px;
}
.rmaBatchInfo::-webkit-scrollbar-thumb {
  background: #186569;
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}
.previousDocumnetTitle {
  margin-right: 38%;
}
.securityClearanceTitle {
  margin-right: 40%;
}

.previousDocumnetTitleShipment {
  margin-right: 28%;
}
.rmaExportValue {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569 !important;
  text-align: center;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 5px;
}

.rmaBatchDetailHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #353945;
  line-height: 19px;
  font-weight: 500;
  margin: 15px 15px 10px 20px;
}
.rmaPendingStatus {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569;
}
.rmaManufactureName {
  font-size: 20px;
  margin-left: 20px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #186569;
}
.rmaManufacturerUnderline {
  margin-left: -20px;
  color: #aeb8c9;
  height: 1.5px;
}
.rmaItemDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3px;
  margin-left: 5%;
  margin-right: 5%;
}
.rmaItemDescriptionList {
  color: #353945;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.105em;
}
.rmaItemSupplierName {
  font-weight: 500;
  line-height: 16px;
  color: #186569;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.105em;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.rmaImportExportLabels {
  font-size: 14px;
  font-weight: 500;
  color: #353945 !important;
  border-bottom: 1px solid #fff;
  text-transform: none !important;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.rmaImportExportLabels:hover {
  border-bottom: 1px solid #186569;
  color: #186569 !important;
}
.rmaExportImportLinkIcon {
  font-size: 15px !important;
  font-weight: bold !important;
  margin-right: 10px !important;
}
.rmaImportExportContainer {
  display: flex;
  flex-direction: column;
  /* margin-right: 15%; */
}
.rmaDownloadBox {
  border: 2px #ececec solid;
  display: flex;
  justify-content: space-between;
  margin-top: -14px;
  padding: 3px;
  align-self: center;
  width: 77%;
  height: 56px;
}

.rmaDownloadLabel {
  margin-left: 4%;
  margin-top: 14px;
  color: #353945;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rmaFileAttachLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: #186569;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.rmaFileAttachLabelDivider {
  border-bottom: 1px solid #e2e7f0;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: -10px;
}
.rmaFileInputLabelContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: max-content;
  gap: 25px;
}
.rmaFileInputLabel {
  margin-top: 8px;
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: center;
  /* margin-top: 8px; */
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.105em;
  color: #353945;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.rmaFileUploader {
  position: relative;
  top: -22px;
  left: 5px;
  width: 5rem;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  letter-spacing: 0.105em !important;
  color: #353945 !important;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.rmaShipmentArrangement {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.rmaItemDescriptionModal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3px;
}
.rmaItemDescriptionListModal {
  color: #353945;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.105em;
}

.logisticsRma-projectModal {
  background-color: #fefefe;
  margin-left: 61.6%;
  height: 100%;
  border: 1px solid #888;
  width: 518px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}

.logisticsRma-create-project-modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* Text */
  margin-top: 5px;
  margin-bottom: 35px;
  color: #353945;
  height: 45px;
  border-bottom: 1px solid #e2e7f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: unset !important;
}

.shipment-arra-radio-button-lable {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
}

.rma-form-submit {
  margin-left: 35%;
}
.missedItem-form-submit {
  margin-left: 20%;
}
.rmaMissedItemFile-choosers {
  width: 260px;
  margin-bottom: 10px;
}
.missed-item-logistics-batch-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945;
}
.missed-item-origin-group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.missed-items-batch-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 70px;
}
.updateEtd {
  margin-right: 60%;
}
.missedItemRmaStatus {
  display: flex;
}
.rma_missed_item_status_detail {
  display: flex;
  border: "1px solid #4e4";
}
@media (max-width: 768px) {
  .logisticsRma-projectModal {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .logisticsRma-create-project-modal-title {
    font-weight: 500;
    font-size: 16px;
  }
  .rmaMissedItemFile-choosers {
    width: 200px !important;
  }
  .rmaMissedItemForm {
    width: 80%;
    margin: auto;
    margin-top: 0px !important;
  }
  .forexApprovalForms {
    /* margin-right: 0px; */
    display: flex;
    justify-content: center;
  }
  .inputLabelsHolder {
    width: 180px;
    display: flex;
    justify-content: center;
    margin-left: -25%;
  }

  .rmaMissedItemFormShipment {
    margin-top: 10px !important;
  }
  .shipmentArrangment {
    height: 80%;
  }
  .rmaBatchInfo {
    height: 465px;
  }
}
