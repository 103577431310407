.sourcing-dashboard {
    display: grid;
    grid-template-columns: 45% 45%;
    margin-top: 1rem;
    gap: 56px;
    justify-content: center;
}
.dashboard-left-section {
  background: #ffffff;
  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  min-height: 300px;
  display: grid;
  padding: 25px;
  gap: 1rem;
}
.sourcing-dashboard-mini-card {
  padding: 15px;
  background: #ffffff;
  width: 100%;
  height: 87px;
  border: 1px solid #e2e7f0;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 135px 40px 140px;
}
.sourcing-dashboard-mini-card-pa {
  padding: 15px;
  background: #ffffff;
  width: 100%;
  height: 87px;
  border: 1px solid #e2e7f0;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: 35px 140px 135px;
}
.button-pa-view {
  background: #186569;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #ffffff !important;
  border: 1px solid #186569;
  cursor: pointer;
}
.button-pa-view:hover {
  background: #fff;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  color: #186569 !important;
  border: 1px solid #186569;
  cursor: pointer;
}
.button-pa-view-white {
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  border: 1px solid #186569;
  border-radius: 6px;
  color: #186569 !important;
  cursor: pointer;
}
.button-pa-view-white:hover {
  background: #186569;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  border: 1px solid #186569;
  border-radius: 6px;
  color: #fff !important;
  cursor: pointer;
}
.button-pa-view-white {
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  border: 1px solid #186569;
  border-radius: 6px;
  color: #186569 !important;
}
.sourcing-dashboard-utility {
  margin-bottom: 30px;
  display: flex;
  gap: 1rem;
}
.mini-card-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.mini-card-controller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.numberOf-order {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.mini-card-controller-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.view-more-po {
  background: #ffffff;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  border: 1px solid #186569;
  border-radius: 6px;
  color: #186569 !important;
  cursor: pointer;
  text-align: center;
}
.view-more-po:hover {
  background: #186569;
  border-radius: 4px;
  border: none;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 24px;
  padding-right: 24px;
  outline: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;
  border: 1px solid #fff;
  border-radius: 6px;
  color: #fff !important;
  cursor: pointer;
  text-align: center;
}
.dashboard-right-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.utilities-buttons-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sourcing-big-page {
  background: #353945;
  /* Text */

  border: 2px solid #353945;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #ffffff;
  height: 60px;
}
.calender-container {
  max-width: 405px;
  background: #ffffff;
  /* Neutral 1 */
  height: fit-content;
  border: 0.5px solid #aeb8c9 !important;
  border-radius: 6px !important;
}
.react-calendar {
  border-radius: 6px !important;
}
.utility-select-option {
  width: 282px;
  height: 30px;
  outline: none;
  border: none;
  background: #eff2f3;
  border-radius: 6px;
}
.create-order-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}
.order-supplier {
  margin-top: 3px;
  margin-bottom: 3px;
  min-height: fit-content !important;
}
.manufacturer-form {
  width: 450px;
  height: 166px;
  left: 947px;
  top: 191px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  border-radius: 4px;
  height: 166px;
  left: 947px;
  top: 191px;

  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  border-radius: 4px;
}
.sourcing-sm-card {
  box-sizing: border-box;
  width: 240px;
  height: 142px;
  background: #ffffff;
  /* Neutral 2 */
  border: 1px solid #e2e7f0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}
.sourcing-postage-cards {
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  gap: 15px;
}
.sourcing-postage-big-btn {
  width: -moz-fit-content;
  width: 100%;
  height: 52px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #353945;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 26px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #353945;
}
.sourcing-postage-big-btn:hover {
  width: -moz-fit-content;
  width: 100%;
  height: 52px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #ffffff;
  color: #353945;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 26px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #353945;
}
.payment-tracking-text {
  height: 12px;
  left: 1051px;
  top: 175px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.105em;
  color: #aeb8c9;
}
.select-order-name {
  width: 338px;
  height: 30px;
  outline: none;
  border: none;
  background: #eff2f3;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.price-comparison {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #ffffff;
  background: #064b4f;
  border-radius: 1rem;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.price-comparison:hover {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  color: #064b4f;
  background: #ffffff;
  border-radius: 1rem;
  height: 32px;
  padding-left: 3px;
  padding-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border: 1px solid #064b4f;
}
.price-comparison-container{
box-sizing: border-box;
position: absolute;
width: 83%;
min-height: 200px;
left: 180px;
top: 114px;
background: #FFFFFF;
border: 0.5px solid #AEB8C9;
border-radius: 6px;
}
.supplier-attachment-container{
  box-sizing: border-box;
  width: 96%;
  height: 54px;
  background: #FFFFFF;
  border: 1px solid #E2E7F0;
  margin-left: 21px;
  margin-right: 21px;
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  justify-content: space-between;
}
.attach-pi-text{
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.105em;
color: #AEB8C9;
width: 20%;
}
.supplier-selector{
box-sizing: border-box;
width: 335px;
height: 32px;
background: #FFFFFF;
border: 1px solid #E2E7F0;
border-radius: 6px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.105em;
color: #AEB8C9;
}
.price-comparison-file-chooser{
  width: 100%;
  background: #eff2f3;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: right center;
  height: 32px;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.supplier-user-container{
  display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 6px;
    margin-bottom: 20px;
}
.user-container-card{
  box-sizing: border-box;
    max-height: 73px;
    background: #ffffff;
    border: 1px solid #aeb8c9;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 920px) {
  .sourcing-dashboard {
    display: grid;
    grid-template-columns: 300px;
    margin-top: 1rem;
    gap: 56px;
  }
  .sourcing-dashboard-mini-card {
    padding: 15px;
    background: #ffffff;
    width: 100%;
    height: fit-content;
    border: 1px solid #e2e7f0;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 200px;
    text-align: center;
    gap: 20px;
  }
  .sourcing-sm-card {
    box-sizing: border-box;
    width: 100%;
    height: 142px;
    background: #ffffff;
    border: 1px solid #e2e7f0;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
  }
  .dashboard-left-section {
    background: #ffffff;
    border: 0.5px solid #aeb8c9;
    border-radius: 6px;
    min-height: 300px;
    display: flex;
    align-items: center;
    padding: 25px;
    flex-direction: column;
    gap: 1rem;
  }
  .sourcing-dashboard-utility {
    margin-bottom: 0px;
    display: flex;
    gap: 1rem;
  }
  .sourcing-postage-cards {
    display: grid;
    grid-template-columns: 100%;
    gap: 13px;
  }
  .card-supplier {
    box-sizing: border-box;
    width: 100%;
    height: 118px;
    top: 149px;
    background: #ffffff;
    border: 0.5px solid #aeb8c9;
    border-radius: 6px;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 15%);
  }
  .manufacturer-form {
    width: 98%;
    height: 166px;
    left: 947px;
    top: 191px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #e2e7f0;
    border-radius: 4px;
    height: 166px;
    left: 947px;
    top: 191px;
    border: 1px solid #e2e7f0;
    border-radius: 4px;
  }
  .input-groups {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
  }
  .utility-select-option {
    width: 230px;
    height: 30px;
    outline: none;
    border: none;
    background: #eff2f3;
    border-radius: 6px;
  }
  .sourcing-dashboard-mini-card-pa {
    padding: 15px;
    background: #ffffff;
    width: 100%;
    height: fit-content;
    border: 1px solid #e2e7f0;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 15%);
    display: grid;
    justify-content: center;
    grid-template-columns: 140px;
    gap: 20px;
    text-align: center;
  }
}
