.document-card-container {
  margin-top: 20px;

  display: grid;
  grid-template-columns: 350px 350px 350px;
  gap: 12px;
}
a {
  text-decoration: none;
}

.document-card {
  box-sizing: border-box;

  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.card-content {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
}
.divider {
  margin-top: 10px;
  border-bottom: 1px solid #ececec;
}
.document-name  {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;
  color: #353945;
  text-align: center;
}
.status {
  width: 61px;
  height: 11px;
  left: 279px;
  top: 198px;

  /* Success */

  background: #52ffac;
  border-radius: 3px;
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
  letter-spacing: 0.105em;
  color: #353945;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.detail {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.number {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: 0.105em;
  color: #186569;
}
.type {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  color: #353945;
}
.batch-select-option {
  width: 160px;
  background: #fff;
  border: 2px solid #186569;
  border-radius: 52px;
  outline: none;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #353945;
  margin: 5px;
}
@media (max-width:920px) {
  .document-card-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100%;
    gap: 12px;
}
.detail {
  margin-top: 20px;
  display: flex;
 justify-content: space-between;
}
.document-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;
  color: #353945;
  text-align: center;
}
}