/* Container for the draggable items */
.drag-and-drop-container {
    display: grid;
    overflow: hidden;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    min-height: 60px;
  }
  
  /* Individual draggable item */
  .draggable-item {
    flex: 1;
    min-width: 100px;
    padding: 10px;
    margin: 5px;
    background-color: #3498db;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Styling when dragging an item */
  .dragging {
    opacity: 0.7;
    transform: scale(0.95);
  }
  
  /* Styling when hovering over an item */
  .drag-over {
    border: 2px dashed #3498db;
    background-color: transparent;
  }
  