.ci-container {
  display: grid;
  grid-template-columns: 260px 260px 260px 260px;
  margin-top: 20px;
  gap: 15px;
}
.ci-card {
  padding-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 140px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  gap: 30px;
}
.ci-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Primary */

  color: #064b4f;
}
.ci-project {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.client-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.105em;
  /* Text */
  color: #353945;
}
.label-small {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0.105em;
  /* Neutral 1 */
  color: #aeb8c9;
}

.ci-container {
  display: grid;
  grid-template-columns: 260px 260px 260px 260px;
  margin-top: 20px;
  gap: 15px;
}
.ci-card {
  padding-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 140px;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  gap: 30px;
}
.ci-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Primary */

  color: #064b4f;
}
.ci-project {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.client-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.105em;
  /* Text */
  color: #353945;
}
.label-small {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  letter-spacing: 0.105em;
  /* Neutral 1 */
  color: #aeb8c9;
}
.comparison-container {
  display: flex;

  gap: 10px;
  grid-template-columns: 300px 500px;
}
.ci-form-check {
  width: 400px;
  height: fit-content;

  background: #ffffff;
  /* Neutral 1 */

  border: 1px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pdf-view-container {
  width: 650px;
  height: 500px;
  overflow-y: scroll;
}
.ci-labels {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.ci-checklist {
  margin-top: 20px;
  display: flex;
}
.controllers {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}
.check-list-items {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.check-list-values-container {
  display: flex;
}
.check-list-values {
  display: flex;
  gap: 20px;
}
.number-input {
  width: 35px;
  height: 16px;

  background: #d9d9d9;
  border-radius: 3px;
  outline: none;
  border: none;
}
.sm-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.group {
  display: flex;
  align-items: center;
  gap: 5px;
}
.full-divider {
  border-bottom: 1px solid #aeb8c9;
  width: 100%;
  height: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.ci-divider {
  border-bottom: 1px solid #aeb8c9;
  width: 320px;
  height: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.comparison {
  display: flex;
  gap: 30px;
  align-items: center;
}
.terms-condition {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
.send-for-approval-btn {
  width: 217.72px;
  outline: none;
  border: none;
  height: 32px;
  background: #52ffac;
  border-radius: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.send-for-approval-btn-dissable {
  width: 217.72px;
  outline: none;
  border: none;
  height: 32px;
  background: #eff2f3;
  border-radius: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.send-for-approval-btn-dissable {
  width: 217.72px;
  outline: none;
  border: none;
  height: 32px;
  background: #ececec;
  border-radius: 25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}

.send-for-approval-btn:hover {
  border: 1px solid #353945;
  background: #353945;
  color: #52ffac;
}
.term {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */
  display: flex;
  color: #353945;
  gap: 25px;
}
.terms .select-option {
  width: 234px !important;
}
.copy-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #064b4f;
  border: none;
  outline: none;
  cursor: pointer;
}

.term-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.group-input {
  display: grid;
  grid-template-columns: 2fr 3fr;
  justify-content: flex-end;
  gap: 2rem;
}
.group-input .select-option {
  width: 200px;
}
.check-box-ci {
  margin-left: 2rem;
  width: 18px;
  height: 18px;

  /* Accent */

  border: 2px solid #186569;
  border-radius: 2px;
}
.terms {
  gap: 2px;
  display: flex;
  flex-direction: column;
}
.mandatory-documents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.term-values {
  padding-top: 8px;
  border: none;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  width: 234px;
  height: fit-content !important;
  letter-spacing: 0.105em;
  outline: none;
  /* Text */
  color: #353945;
}
.lc-warning {
  height: 52px;

  /* Accent */

  background: #186569;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Neutral (Light) / N0 */

  color: #ffffff;

  /* Inside auto layout */
  display: flex;
  justify-content: center;
  gap: 4px;
}
.bill-of-loading-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #aeb8c9;
}
.bill-of-loading-section-label {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */
  color: #353945;
  width: 200px;
  margin-bottom: 4px;
}
.check-list-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Primary */

  color: #064b4f;
}
.documents-from-h {
  display: grid;
  grid-template-columns: 8fr 4fr 4fr;
  justify-content: space-between;
  text-align: center;
  gap: 5px;
}
.document-type {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.document-from-container {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.doc-num {
  width: 72px;
  height: 20px;

  /* Body */

  background: #eff2f3;
  outline: none;
  border: none;
  border-radius: 6px;
}
.edit-term {
  border: none;
  outline: none;
  background-color: initial;
}
.download-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-bottom: 25px;
}
.missed-card-information {
  display: flex;
  width: 96%;
  margin-left: 1rem;
}
.creategroup-card-information {
  display: flex;
  width: 80%;
  margin-left: 5rem;
}
.existing-group-selector {
  width: 335px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #e2e7f0;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.105em;
  color: #353945;
  margin-bottom: 10px;
}
.existingDownloadBox {
  border: 2px #ececec solid;
  display: flex;
  justify-content: space-between;
  margin-top: -14px;
  padding: 3px;
  align-self: center;
  width: 100%;
  height: 56px;
}

.document-preparation-approval-card-container {
  display: grid;
  grid-template-columns: 300px 810px;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
  /* margin-right:15px; */
}
