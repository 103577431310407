.prg_supplier {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.105em;
  font-weight: 400;
}

.supplier_category_container {
  box-sizing: border-box;
  width: 100%;
  min-height: 425px;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  border-radius: 6px;
}

.supplier_category_Table_Container {
  margin: 20px;
}
