.notification-display-none {
  display: none;
}
.notification-card-container {
  width: 50%;
  /* height: 100vh; */
  background-color: #fff;
  position: absolute;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  top: 99%;
  right: 5%;
  justify-content: center;
  border-radius: 6px;
  z-index: 50;
}
.notification-bar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: default;
}
.notification-seen-button,
.notification-new-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: #fff;
  height: 2.5rem;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.notification-new-button {
  background-color: #186569;
}
.notification-seen-button {
  background-color: #353945;
}
.notification-new-button:hover {
  background-color: #064c50cc;
}
.notification-seen-button:hover {
  background-color: #353945c7;
}
.all-notifications-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 280px;
}
.single-notification-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 0px 30px 40px;
}
.single-notification-container-for-seen {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 6px 0px 10px 40px;
}
.single-notification-container:hover,
.single-notification-container-for-seen:hover {
  background-color: #e2e7f0;
}
.notification-module-name-and-date {
  display: flex;
  justify-content: flex-start;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #aeb8c9;
  margin-top: 2px;
}
.notification-value {
  width: inherit;
  color: #353945;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding-right: 70px;
}
.notification-status-icon {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  margin-top: -35px;
}

.notification-footer-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.notification-mark-as-read-button {
  border-bottom-left-radius: 5px;
  border-right: 1px solid #aeb8c9;
}
.notification-view-all-button {
  border-bottom-right-radius: 5px;
}
.notification-mark-as-read-button,
.notification-view-all-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: #186569;
  background-color: #e2e7f0;
  height: 2.5rem;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: default;
}
.notification-view-all-full-width {
  width: 100%;
  border-bottom-left-radius: 5px;
}
.notification-mark-as-read-button:hover,
.notification-view-all-button:hover {
  background-color: #e2e7f079;
}
.notification-module-date-divider {
  font-size: 34px;
  margin-top: -10px;
  margin-left: 20px;
  margin-right: 20px;
}

.single-notification-container #cancel {
  display: none;
}
.single-notification-container:hover #cancel {
  display: flex;
}
.single-notification-container:hover .notification-status-icon {
  display: none;
}
