.loginLeft {
  background: url("./loginLogo.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}
.systemInfo {
  height: 50%;
  width: 53%;
  margin-top: 20%;
  border-radius: 12px;
}

.loginHeading {
  color: #064b4f;
  font-size: 28px;
  font-weight: 700;
  padding-top: 15%;
  display: flex;
  justify-content: center;
}
.loginDetail {
  padding-left: 12%;
  padding-right: 10%;
  padding-top: 2%;
}

.loginFooter {
  color: #575757;
  margin-top: 50%;
  font-size: 28px;
  font-weight: 600;
}
.loginLogo {
  width: 80px !important;
  height: 80px !important;
  border-radius: 0 !important;
  margin-bottom: 50px;
}
.loginPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 40%;
}
.login-page-main-container {
  width: 100%;
}
@media (max-width: 768px) {
  .login-page-main-container {
    width: 105%;
  }
  .loginLogo {
    margin-top: 10px;
  }
  .login-window-view {
    display: none;
  }
  .login-mobile-view {
    margin-top: 50%;
  }
}
