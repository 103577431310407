@media (max-width: 768px) {
  .document-card-container {
    grid-template-columns: 300px 300px;
    justify-content: center;
  }
  .utilities {
    justify-content: center;
  }
}
@media (max-width: 426px) {
  .document-card-container {
    grid-template-columns: 300px;
  }
}
