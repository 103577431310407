[data-n-id] rect {
  fill: #ffffff;
  border-radius: 6px;
}
[data-n-id] text {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  fill: #353945;
}
.boc-light {
  background: #eff2f3 !important;
}
