.logistics-batch-detail-main {
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 20px;
}
.logistics-batch-detail {
  height: 502px;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  padding: 25px;
  overflow-y: scroll;
  gap: 10px;
}
.batch-header {
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  margin-bottom: 2rem;
}
.owner-name {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.batch-comment {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.date-card {
  height: 68px;
  left: 1002px;
  top: 114px;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.date-value-logistics {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.card-sm-label-logistics {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.country-value-logistics {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.status-card {
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 25px;
  height: 166px;

  background: #ffffff;
  border: 0.5px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.logistics-officer-card {
  height: 96px;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.card-md-label-note {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.select-option-logistics {
  width: 335px;
  height: 32px;

  background: #ffffff;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.logistics-batch-container {
  display: grid;
  grid-template-columns: repeat(3, 32.5%);
  gap: 20px;
}
.batch-detail-card {
  height: 154px;

  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.batch-detail-card-sub {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.batch-card-left-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.logistics-batch-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #353945;
}
.batch-date {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  text-align: left;
}
.origin-group {
  display: flex;
  flex-direction: column;
}
.origin-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.origin-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.logistics-batch-items-container {
  display: flex;

  flex-direction: column;
  gap: 15px;
}
.batch-container {
  display: grid;
  flex-direction: column;
  align-items: flex-start !important;
}

.logistics-batch-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.logistics-batch-value {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.logistics-batch-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.logistics-batch-items-container {
  display: flex;

  flex-direction: column;
  gap: 15px;
}
.batch-container {
  display: grid;
  flex-direction: column;
  align-items: flex-start !important;
}
.logistics-batch-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.logistics-batch-value {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.logistics-batch-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.batch-detail-footer {
  width: 100%;
  font-weight: 600;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #ececec;
}
.according-container {
  border: 1px solid #ececec;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
.according-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.batch-index-container {
  margin-top: 60px;
  height: 400px;

  background: #ffffff;
  /* Neutral 1 */

  border: 0.5px solid #aeb8c9;
  border-radius: 6px;

  padding-top: 30px;
  display: flex;
  flex-direction: column;
  
}
.batch-index-controllers {
  display: grid;
  grid-template-columns: repeat(2, 45%);
  gap: 30px;
  justify-content: center;
}
.special {
  margin-top: 30px;

  width: 79%;
}
.batch-index-controller {
  height: 87px;
  background: #ffffff;
  /* Neutral 2 */

  border: 1px solid #e2e7f0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}
.controller-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
@media (max-width: 920px) {
  .batch-index-container {
    margin-top: 60px;
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border: 0.5px solid #aeb8c9;
    border-radius: 6px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .special {
    margin-top: 10px;
    width: 85% !important;
  }
  .batch-index-controllers {
    display: grid;
    grid-template-columns: 100%;
    gap: 30px;
    margin: 20px;
  }
  .logistics-batch-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
  }
  .batch-detail-header-page {
    gap: 25px;
    margin-top: 25px;
    display: grid;
    grid-template-columns: 100%;
  }
}
