.report-project-selector{
width: 351px;
height: 40px;
background: #EFF2F3;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #AEB8C9;
border: none;
}
.report-header-container{
box-sizing: border-box;
width: 100%;
height: 152px;
background: #FFFFFF;
border: 1px solid #E2E7F0;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
padding-left: 20px;
padding-right: 20px;
padding-top: 28px;
padding-bottom: 28px;
display: grid;
gap:16px
}
.report-session-btn-active{
width: fit-content;
height: 34px;
background: #186569;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #FFFFFF;
padding-left: 24px;
padding-right: 24px;
padding-bottom: 10px;
padding-top: 10px;
border:none
}

.report-session-btn{
width: fit-content;
height: 34px;
background: #E2E7F0;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #353945;
padding-left: 24px;
padding-right: 24px;
padding-bottom: 10px;
padding-top: 10px;
border:none
}
.report-module-select-active{
width: 20px;
height: 20px;
background: #186569;
border-radius: 2px;
border:none
} 
.report-module-select{
width: 20px;
height: 20px;
background: #D9D9D9;
border-radius: 2px;
border:none
} 
.module-label{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #353945;
}
.report-filter-selector{
    width: 100%;
    height: 40px;
    background: #EFF2F3;
    border-radius: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.105em;
    color: #AEB8C9;  
    border: none;

}
.report-sourcing-container{
box-sizing: border-box;
width: 100%;
height: fit-content;
background: #FFFFFF;
border: 1px solid #E2E7F0;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
}
.report-download{
padding: 10px 24px;
width: 166px;
height: 34px;
background: #FF5252;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #FFFFFF;
border:none;
margin: 32px 14px;

}
.report-project-total{
width: 100%;
height: 54px;
background: #E2E7F0;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13);
border-radius: 2px;
padding: 15px 30px;
margin: 32px 14px;
}

.report-sourcing-table-header{
width: 99%;
height: 39px;
background: #E2E7F0;
box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13);
border-radius: 2px;
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
letter-spacing: 0.105em;
color: #353945;
margin-left: 5px;
margin-right: 5px;
}
.sourcing-table-headers{
    display: grid;
    grid-template-columns: 21.5% 9.5% 9.5% 9.5% 10.5% 16.5% 15.5% 12.5%;
    align-items: center;
    height: 39px;
    padding: 0px 20px;
}
.finance-table-headers{
    display: grid;
    grid-template-columns: 20.5% 9.5% 9.5% 9.5% 9.5% 9.5% 9.5% 9.5% 9.5%;
    align-items: center;
    height: 39px;
    padding: 0px 20px;
}
.logistics-table-headers{
    display: grid;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6% ;
    align-items: center;
    height: 39px;
    padding: 0px 20px;
}
.report-date{
width: 431px;
height: 40px;
background: #EFF2F3;
border-radius: 3px;
border: none;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #AEB8C9;
}
.report-download-container{
box-sizing: border-box;
width: 429px;
height: 56px;
background: #FFFFFF;
border: 1px solid #ECECEC;
box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
padding: 0px 20px;
}
.report-order-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
}
.report-ordername{
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 15px;
letter-spacing: 0.105em;
color: #353945;
}
.report-card{
    overflow: auto;
    overflow-x: hidden;
    max-height: 465px;
}