.forex-detail-container {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  gap: 20px;
}
.payment-menu-card{
  background-color: white;
  height: 180px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.08);
  flex-grow: 3;
  flex-basis: 25%;
}

.forex-page {
  min-height: 100vh;
  background: #eff2f3;
  padding-left: 50px;
  padding-right: 50px;
}

.initiateForexButton {
  display: flex;
  justify-content: center;
  
}

.widthExtend {
  width: 114%;
}

@media (max-width: 496px) {
  .widthExtend {
    width: 100%;
  }
  .paymentDetailWrap {
    width: 100px;
  }
  .forex-detail-container {
    display: flex;
    flex-direction: column;
  }
  .forex-page {
    width: 88%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
