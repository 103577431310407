.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin-left: 40%;
  height: 100%;
  border: 1px solid #888;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh; /* Full height */
  height: fit-content;
}
.create-project-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */
  padding-left: 20px;
  color: #353945;
  height: 60px;
  border-bottom: 1px solid #e2e7f0;
  padding-right: 1rem;
}
.form-card-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.form-card-information {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
}
.project-information {
  border: 1px solid #e2e7f0;
  border-radius: 8px;
  height: fit-content;
}

.client-information {
  border: 1px solid #e2e7f0;
  border-radius: 8px;
  height: 163px;
}
.card-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-form {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  justify-content: center;
  margin-bottom: 5px;
}
.form-card-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */
  height: 30px;
  color: #353945;
  padding-left: 1rem;
  align-items: center;
  display: flex;
  border-bottom: 0.5px solid #e2e7f0;
  justify-content: space-between;
}
.input-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.105em;

  color: #000000;
}
.input-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: max-content;
  gap: 25px;
}
.input-group-fine {
  display: flex;

  flex-direction: column;
  align-items: flex-end;
  height: max-content;
  gap: 8px;
}

.assign-personal {
  width: 80%;
  border: 1px solid #e2e7f0;
  border-radius: 8px;
  height: fit-content;
  margin-left: 1rem;
  margin-right: 1rem;
  align-self: center;
}
.assign-personal-card {
  display: grid;
  grid-template-columns: 100%;
  padding-left: auto;
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  gap: 10px;
}
.form-cancel {
  width: fit-content;
  height: 32px;
  background: #aeb8c9;
  border-radius: 25px;
  border: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.form-save {
  width: fit-content;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #064b4f;
  border-radius: 25px;
  color: #ffffff;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}
.form-save-disable {
  width: fit-content;
  height: 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;
  background: #bcd0d1;
  border-radius: 25px;
  color: #000;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
}

.form-save:hover {
  background-color: #186569;
}

.form-cancel:hover {
  background-color: #eff2f3;
}
.check-box-field {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Accent */
  width: fit-content;
  color: #186569;
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 3rem;
}
.check-box-field {
  height: fit-content;
  color: #186569;
}
::placeholder {
  color: #eff2f3;
}
@media (max-width: 768px) {
  ::placeholder {
    color: inherit !important;
  }
  .mini-modal-content {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .input-group-grid {
    grid-template-columns: 200px;
  }
  .create-batch-option-group {
    justify-content: center;
  }
  .medium-modal-content {
    width: 100% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .form-card-information {
    display: flex;
    flex-direction: column;
  }
  .assign-personal-card {
    display: flex;
    flex-direction: column;
  }
}
.sub-solutions {
  height: fit-content;
}
