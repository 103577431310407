@media print {
    body {
      margin: 0; /* Reset margin for printing */
    }
  
    .container {
      width: 100%; /* Make the container full width for printing */
      margin: 0; /* Reset margin for printing */
    }
  
    .img-fluid {
      max-width: 100%; /* Ensure the image fits within the printable area */
      height: auto; /* Maintain the image's aspect ratio */
    }
  
    .max-width-100 {
      max-width: 100%; /* Limit maximum width for printed content */
    }
  }