.order-card-container {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 25px;
}
.order-card {
  box-sizing: border-box;

  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.order-information-container {
 
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  align-items: flex-start;
}
.order-info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.order-name {
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #353945;
}
.order-project {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}
.project-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;
}
.project-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.sourcing-officer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sourcing-officer-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.sourcing-officer-name {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.sourcing-officer-designation {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.order-supplier {
  height: fit-content;
  text-align: left;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.105em;
  color: #353945;
  padding-left: 12px;
}

  /* Text */


.eta-card {
  box-sizing: border-box;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bill-card {
  box-sizing: border-box;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #aeb8c9;
  border-radius: 6px;

}
.eta-btn {
  width: fit-content;
  height: 40px;
  left: 1082px;
  top: 221px;
  border: 0px solid #353945;
  background: #186569;
  border-radius: 6px;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.105em;
}
.paymenttracking-card-container {
  display: grid;
  grid-template-columns: 327px 327px 327px;
  gap: 20px;
  margin-top: 29px;
}

.paymenttracking-card {
  box-sizing: border-box;
  height: 153px;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymenttracking-table-card {
  box-sizing: border-box;
  background: #ffffff;
  width: 1023px;
  border-radius: 6px;
}
.paymenttracking-value {
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  letter-spacing: 0.105em;
  color: #064b4f;
}
.paymenttracking-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  letter-spacing: 0.105em;

  /* Neutral 1 */

  color: #aeb8c9;
}
.paymenttracking-text-project {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.payment-select-btn {
  background: #353945;
  border-radius: 6px;
  width: 102px;
  height: 28px;
  color: white;
  text-align: center;
  border: 0px solid #353945;
}
.payment-project-name {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;

  /* Text */

  color: #353945;
}
.payment-table-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  /* identical to box height */

  letter-spacing: 0.105em;

  /* Accent */

  color: #186569;

  transform: matrix(1, 0, 0, 1, 0, 0);
}
.payment-table-body {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* Text */

  color: #353945;

  transform: matrix(1, 0, 0, 1, 0, 0);
}
@media (max-width: 920px) {
  .order-card-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 25px;
  }
}
