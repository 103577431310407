.menu-container{
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  gap: 40px;
}
.setting-menu-card{
  background-color: white;
  height: 170px;
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.08);
}


.card-header-setting {
  height: 45px !important;
}
.permission-display-container{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.setting-card-shipment {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  height: fit-content;
  width: 460px;
}
.projectSettingButton {
  height: 35px;
  width: 25px;
  border-radius: 100%;
  margin: "auto";
  padding: auto;
}
.projectSettingButton:hover {
  height: 35px;
  width: 25px;
  border-radius: 100%;
  margin: "auto";
  padding: auto;
}

.devLine {
  /* border-top: "0px solid #ececec"; */
  /* width: "400px"; */
  margin-top: "-5px" !important;
  /* margin: auto; */
}
.repreSave:hover,
.clientSave:hover {
  background-color: #186569;
}
.clientCancel:hover,
.repreCanel:hover {
  background-color: #eff2f3;
}

.clientSave,
.repreSave {
  width: 112px;
  height: 32px;
}
.clientForm {
  margin-left: 28%;
}
.repreForm {
  margin-left: 35%;
}
.setting-accordion-p {
  display: grid;
  grid-template-columns: 399px 238px 170px 270px 20px;
  
}
.settingRepr {
  display: grid;
  grid-template-columns: 130px 190px 110px 145px 0;
  gap: 50px;
}
.setting-accordion-header {
  display: grid;
  grid-template-columns: 494px 238px 170px 150px 166px;
    margin-top: 1%;
}
.accordionHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.headerStyle {
  font-weight: 500;
}
.breadCrampNav {
  color: #575757;
}
.editDeleteButton {
  display: flex;
  justify-content: space-between;
  margin-left: -43px;
  margin-top: 0px;
}

.addRepTitle {
  margin-right: 42%;
}

.addClientTitle {
  margin-right: 65%;
}
.clientRepModal {
  flex-direction: unset !important;
}

.setting-projectModal {
  background-color: #fefefe;
  margin-left: 61.6%;
  height: 100%;
  border: 1px solid #888;
  width: 518px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}

.setting-projectModal-input {
  width: 249px;
  left: 1042px;
  top: 130px;
  height: 32px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
}

.setting-create-project-modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.105em;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* Text */
  margin-top: 5px;
  color: #353945;
  height: 45px;
  border-bottom: 1px solid #e2e7f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.setting-projectModal-input-labels {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: max-content;
  gap: 25px;
  margin-top: 7px;
}

.project-inputs {
  line-height: 16px;
  letter-spacing: 0.105em;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.105em;
  color: #353945;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.repInputField {
  margin-left: -19px;
}
.setting-useraccess-container{
  display: grid;
    grid-template-columns: 49% 49%;
    gap: 20px;
    
}
.role-card{
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 0.5px solid #AEB8C9;

}
.role-card-container{
  display: grid;
  gap: 20px;
  padding: 20px;
}
.accordion-item {
  background-color: #fff;
  border: 0px !important;
  border-bottom:1px solid rgba(0,0,0,.125) !important;
}
.accordion-button:not(.collapsed) {
  color: #186569 !important;
  background-color: #fff !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.setting-select-option{
width: 335px;
height: 32px;
background: #FFFFFF;
border: 1px solid #E2E7F0;
border-radius: 6px;
outline: none;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.105em;
color: #AEB8C9;
}
.permission-table-header{
    display: grid;
    grid-template-columns: 186px 286px auto auto;
    margin-left: 56px;
    margin-top: 20px;
 
}.permission-table-body{
  display: grid;
  grid-template-columns: 183px auto 520px auto;
  align-items: center;
}
.permission-divider{
    margin-top: 0px;
    border-bottom: 1px solid #ececec;

}
.permission-card {
    width: 944px;
    min-height: fit-content;
    margin-top: 6px;
    margin-left: 34px;
    background: #FFFFFF;
    border: 0.5px solid #AEB8C9;
    margin-bottom: 22px;
}

.permission-card-container{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;

}
.nav-tabs {
  border-bottom: 0px solid #dee2e6 !important;
}
.setting-representative-container {
  display: flex !important;
  justify-content: space-between;
  height: 38px;
  align-items: center;
}
.permission-display-name{
width: 335px;
height: 32px;
border: 1px solid #E2E7F0;
border-radius: 6px;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: #E2E7F0;
letter-spacing: 0.105em;

}
.username-search{
box-sizing: border-box;
width: 414px;
height: 35px;
border: 1px solid #AEB8C9;
border-radius: 20px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #353945;
margin: 23px;
}
.users-table-header{
width: 100%;
height: 64px;
background: #E2E7F0;
color:black
}
.user-table-data{
    border: 1px solid #E2E7F0;
    text-align: left;
    padding: 8px;
}
.username{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
letter-spacing: 0.105em;
color: #353945;
}
.user-email{
  font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
/* identical to box height */

letter-spacing: 0.105em;

/* Text */

color: #353945;
}
.userinfo{
  display: flex;
  align-items: center;
  gap: 5px;
}
.user-role-rounded{
  width: fit-content;
    height: fit-content;
    background: #52FFAC;
    border-radius: 25px;
    padding-top: 4px;
    padding-bottom: 2px;
}
.edit-user-permission-modal-content {
  background: #ffffff;
  border-radius: 6px;
  width: 447px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
}
#camera--icon input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  
}
.Store-row {
  display: grid;
  grid-template-columns: 17% 17% 17% 17% 17% 15%;
  text-align: center;
  min-height: 70px;
  align-items: center;
  border: 1px solid #e2e7f0;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 15%);
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.Store-header-row {
  display: grid;
  grid-template-columns: 17% 17% 17% 17% 17% 15%;
  text-align: center;
  min-height: 70px;
  align-items: center;

  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.105em;
  color: #353945;
}
.user-profile {
  background-color: #fff;
  width:29%;
 
  flex-direction: column;
  align-items: center;

}
.user-card-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.account-input-fields{
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
}
.account-text-input{
  width: 250px;
  height: 50px;
  left: 1042px;
  top: 130px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 20px;
  border: 1px solid #eff2f3;
}
.edit-permission{
  height: 215px;
    overflow: auto;
}
.showpassword{
  position: relative;
  top: -5px;
  right: 40px;
  color: #064b4f;

}
.stock-text-input {
  width: 249px;
  height: 32px;
  left: 1042px;
  top: 130px;
  background: #eff2f3;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 14px;
}
.label{
  display: inline-block;
  width: 150px;
  text-align: right;
}
@media (max-width:920px) {
  .menu-container{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
  }
  .setting-accordion-p{
    display: grid;
    grid-template-columns: 100%;
    gap: 0px;
}
.user-profile {
  background-color: #fff;
  width: 29%;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.user-card-center {
  display: grid;
  justify-content: center;
  align-items: baseline;
}
.setting-accordion-header{
  display: none;
}
.accordionHeader {
  display: block;
  justify-content: space-between;
}
.settingRepr {
  display: grid;
  grid-template-columns: 100%;
 gap: 0px;
}
.editDeleteButton {
  display: flex;
  justify-content: center;
  margin-left: 0px;
  margin-top: 0px;
}
.setting-projectModal {
  background-color: #fefefe;
  margin-left: 0%;
  height: 100%;
  border: 1px solid #888;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 100vh;
  height: -moz-fit-content;
  height: fit-content;
}
.pcard {
  width: 100% !important;
  min-height: 100px;
  background-color: #fff;
  margin-top: 16px;
  margin-right: 0px !important;
  border: 1px solid #AEB8C9;
}
.setting-useraccess-container {
  display: grid;
  grid-template-columns: 100%;
  gap: 23px;
}
.role-card {
  width: 65%;
  height: 40px;
  margin-top: 18px;
  margin-left: 61px;
  background: #FFFFFF;
  border: 0.5px solid #AEB8C9;
}
.permission-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}
.permission-table-header {
  display: none;
}
.permission-table-body {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
}
.permission-card {
  width: 98%;
  min-height: -moz-fit-content;
  min-height: fit-content;
  margin-top: 11px;
  margin-left: 7px;
  margin-right: 0px;
  background: #FFFFFF;
  border: 0.5px solid #AEB8C9;
  margin-bottom: 11px;
  
}
.permission-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
}
.supplier-product-rounded-text {
  width: -moz-fit-content;
  width: fit-content;
  height: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 12px;
  letter-spacing: 0.105em;
  color: #353945;
}
.users-table-header {
  width: 100%;
  height: 64px;
  background: #E2E7F0;
  color: black;
}
}