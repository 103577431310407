*{
    box-sizing: border-box;
}
.pbody-container {
    display: grid;
    grid-template-columns: 50% 50%;
}

.pcard{
    min-height: 100px;
    background-color: #fff;
    margin-top: 16px;
    margin-right: 24px;
    border: 1px solid #AEB8C9;
}

/* Incoterm card styles*/
.pi-header {
    padding: 12px 12px 0 12px;
    border-bottom: 1px solid #E2E7F0;
    margin-bottom: 16px;
}
.pi-header > h3 {
    font-size: 16px;
    color: #575757;
}
.pi-body {
    display: flex;
    width: 90%;
    min-height: 60px;
    justify-content: space-between;
    border: 0.5px solid #AEB8C9;
    margin: 8px auto;
    align-items: center;
    padding: 4px;
}
.pi-body p {
    color: #575757;
    font-size: 14px;
    padding: 6px 12px;
    transform: translateY(16%);
}
.pi-footer{
    display: flex;
    /* flex-direction: row-reverse; */
    padding: 24px;
    justify-content: space-between;
}
.pi-footer-icon {
    display: flex;
    width: 30px;
    height: 30px;
    background-color: #064B4F;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15) ;
}
.pi-body>div:last-of-type {
    display: flex;
}

/* styles written after this section are specifically for the bank module */

.bnk-container {
    width: 100%;
    min-height: 100px;
    background-color: #fff;
    margin-top: 16px;
    margin-right: 24px;
    border: 1px solid #AEB8C9;
    display: flex;
    flex-direction: column;
    padding: 12px 12px;
}
.bnk-header {
    display: flex;
    width: 82%;
    justify-content: space-between;
    padding: 24px;
    margin-left: 0.5rem;
}
.bnk-detail {
    display: flex;
    width: 95%;
    min-height: 70px;
    justify-content: space-between;
    border: 0.5px solid #AEB8C9;
    margin: 8px auto;
    align-items: center;
    padding: 4px 12px;
}
.bnk-detail > span {
    max-width: 10%;
}
.bnk-footer {
    align-self: flex-end;
}
@media (max-width: 920px) {
    .bnk-header {
        display: none;
    }
    .bnk-detail {
        display: grid;
        width: 95%;
        min-height: 70px;
        justify-content: initial;
        border: 0.5px solid #AEB8C9;
        margin: 8px auto;
        align-items: center;
        padding: 4px 12px;
    }
}