.guarantee-container{
    background: #fff;
    min-height: 200px;
    width: 100%;
}
.guarantee-project-container{
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 20px;
}
.guarantee-project-card{
    background-color: #fff;
    height: 154px;
    border-radius: 6px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.08);;
}
.project-ref-container{
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 10px;
}