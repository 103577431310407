.notification-page-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.notification-tab-container {
  width: 40%;
  display: flex;
  justify-content: center;
  cursor: default;
  margin-top: 40px;
}

.notification-seen-tab,
.notification-new-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  color: #fff;
  height: 2.5rem;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.notification-new-tab {
  background-color: #186569;
}
.notification-seen-tab {
  background-color: #353945;
}

.notification-new-tab:hover {
  background-color: #064c50cc;
}
.notification-seen-tab:hover {
  background-color: #353945c7;
}
.notifications-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  margin: 30px;
  height: 467px;
  width: 95%;
  background-color: #fff;
}
.notifications-list-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.notification-detail-mark-all-as-read {
  display: inline-flex;
  justify-content: flex-end;
  padding: 10px 20px 10px 0px;
  color: #186569;
  height: 2.5rem;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}
.notification-detail-mark-all-as-read:hover {
  color: #18656948;
}
.detail-page-single-notifications {
  padding-bottom: -20px;
}
.detail-page-single-notifications-for-seen {
  padding-bottom: 10px;
}
