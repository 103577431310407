.topBar {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  padding-top: 10px;
}
.buttonExport {
  background-color: #347e7d;
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.exportIcon {
  display: flex;
  justify-content: center;
  padding: 20px;
  gap: 2rem;
}
.recivableTr {
  border-width: 1px;
}
.recivableTh,
.recivableTd {
  padding-left: 10px;
  padding-right: 20px;
  overflow: hidden;
  margin-left: 20px;
  min-width: 50px;
  height: 40px;
}

.recivableTable {
  margin-bottom: 30px;
  width: 90%;
  overflow: auto;
}

.recivabletbody {
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
